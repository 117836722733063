import { NavLink } from "react-router-dom";
import MenuButton from "../../components/MenuButton";
import { connect } from "react-redux";

function FinancesHome({ user }) {
  return (
    <div className="personnel-page">
      <div className="w3-row">
        <div className="w3-container">
          <header class="w3-container w3-theme w3-padding" id="myHeader">
            <div class="w3-center">
              <p class="w3-xlarge w3-animate-bottom">
                Veuillez selectionner parmi les options suivantes
              </p>
            </div>
          </header>

          <div class="w3-row-padding w3-center w3-margin-top">
            <MenuButton
              title={"Transactions financières"}
              // desc={
              //   "Effectuer les dépenses opérationelles, règlements clients et autres"
              // }
              icon={"fa fa-arrows"}
              w3color={"w3-indigo"}
              link={"transactions"}
              addToClass={"w3-margin-top"}
            />
            <MenuButton
              title={"Créances"}
              // desc={
              //   "Consulter soldes des caisses et historique des transactions journalières"
              // }
              icon={"fa fa-handshake-o"}
              w3color={"w3-dark-gray"}
              link={"creances"}
              addToClass={"w3-margin-top"}
            />
            <MenuButton
              title={"Caisses"}
              // desc={
              //   "Consulter soldes des caisses et historique des transactions journalières"
              // }
              icon={"fa fa-archive"}
              w3color={"w3-green"}
              link={"caisses"}
              addToClass={"w3-margin-top"}
            />
            {user && user.accessFinances > 1 ? (
              <MenuButton
                title={"Banques"}
                // desc={
                //   "Consulter soldes bancaires et historique des transactions bancaires"
                // }
                icon={"fa fa-bank"}
                w3color={"w3-purple"}
                link={"banques"}
                addToClass={"w3-margin-top"}
              />
            ) : (
              ""
            )}

            {/* 
            <MenuButton
              title={"Comptes clients"}
              desc={
                "Consulter soldes bancaires et historique des transactions bancaires"
              }
              icon={"fa fa-users"}
              w3color={"w3-dark-gray"}
              link={"banques"}
              addToClass={"w3-margin-top"}
            />

            <MenuButton
              title={"Comptes fournisseurs"}
              desc={
                "Consulter soldes bancaires et historique des transactions bancaires"
              }
              icon={"fa fa-briefcase"}
              w3color={"w3-dark-gray"}
              link={"banques"}
              addToClass={"w3-margin-top"}
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

export default connect(mapStateToProps)(FinancesHome);
