import { useEffect, useState, useMemo } from "react";
import {
  Link,
  NavLink,
  Outlet,
  useLoaderData,
  useNavigate,
} from "react-router-dom";
// import Table from "../../components/ReactTable"

export default function StockLayout() {
  return (
    <div className="repertoire-employes-layout">
      <div className="w3-pannel w3-gray">
        <div className="w3-bar">
          <h3 className="w3-bar-item w3-text-white">
            <strong>
              Stock <i class="fa fa-database" aria-hidden="true" />
            </strong>
          </h3>
          <NavLink
            to=""
            id="theNavLinkMenu"
            className="w3-bar-item w3-button w3-ripple w3-yellow w3-round w3-margin-left w3-margin-top"
          >
            <i className="fa fa-book" aria-hidden="true" /> Stock courant
          </NavLink>
          <NavLink
            to={"retirer"}
            id="theNavLinkMenu"
            className="w3-bar-item w3-button w3-ripple w3-pink w3-round w3-margin-left w3-margin-top"
            target="_blank"
          >
            <i className="fa fa-minus-circle" aria-hidden="true" /> Retirer
            (SCANNER)
          </NavLink>
          <NavLink
            to={"approvisionner"}
            id="theNavLinkMenu"
            className="w3-bar-item w3-button w3-ripple w3-indigo w3-round w3-margin-left w3-margin-top"
            target="_blank"
          >
            <i className="fa fa-plus-circle" aria-hidden="true" />{" "}
            Approvisionner (SCANNER)
          </NavLink>

          {/* <NavLink
            to={"/analytics/stock"}
            id="theNavLinkMenu"
            className="w3-bar-item w3-button w3-ripple w3-teal w3-round w3-margin-left w3-margin-top"
            target="_blank"
          >
            <i className="fa fa-bar-chart" aria-hidden="true" /> BI stock
          </NavLink> */}
          <NavLink
            to="/operations"
            id="theNavLinkMenu"
            className="w3-bar-item w3-button w3-ripple w3-light-grey w3-round w3-margin-left w3-margin-top"
          >
            <i className="fa fa-chevron-circle-left" aria-hidden="true" />{" "}
            Retour au menu Opérations
          </NavLink>
        </div>
      </div>
      <div className="w3-container w3-padding">
        <div className="the-content">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
