import { useLoaderData, useParams } from "react-router-dom";
import useAPIData from "../../../functions/useAPIData";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

var today = new Date();
today.setDate(today.getDate() - 1);
var dd = String(today.getDate()).padStart(2, "0");
var mm = String(today.getMonth() + 1).padStart(2, "0");
var yyyy = today.getFullYear();

const today_ = yyyy + "-" + mm + "-" + dd;

export default function BanquesDetails() {
  const { id } = useParams();

  const thisEntity = useLoaderData().filter((obj) => obj.id == id)[0];

  const transactionsRecentes = useAPIData("transactionFinanciere").filter(
    (x) => x.source == id
  );

  const factures = useAPIData("recetteDepense");

  const toPrint = useRef(null);

  const handlePrint = useReactToPrint({
    documentTitle: "Etat de la banque",
    removeAfterPrint: true,
  });

  return (
    <div className="theEmployeDetails">
      <button
        onClick={() => {
          handlePrint(null, () => toPrint.current);
        }}
        className="w3-btn w3-ripple w3-pale-blue w3-round w3-margin-right"
        style={{ width: "300px" }}
      >
        <i className="fa fa-print" aria-hidden="true"></i> Imprimer
      </button>
      <div className="toPrint" ref={toPrint}>
        <div
          className="w3-panel w3-text-white"
          style={{ backgroundColor: "gray" }}
        >
          <div className="w3-row">
            <h3 className="w3-col" style={{ width: "20%" }}>
              <strong>{thisEntity.nom}</strong>
            </h3>
            <h4 className="w3-col" style={{ width: "20%" }}>
              <b>Solde actuel: {thisEntity.soldeCourant}</b>
            </h4>
            <h4 className="w3-col" style={{ width: "20%" }}>
              Solde précédent: {thisEntity.soldeInitial}
            </h4>
            <h4 className="w3-col" style={{ width: "40%" }}>
              Montant plus récente transaction:{" "}
              {thisEntity.montantTransactionRecente}
            </h4>
          </div>
        </div>

        <div className="w3-panel w3-light-gray">
          <h3>
            Etat actuel du compte et transactions effectuées jusqu'a la plus
            recente
          </h3>
        </div>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {/* <TableCell className="w3-sand">Designation</TableCell> */}
                <TableCell>
                  <b>Date</b>
                </TableCell>
                <TableCell>
                  <b>Libellé</b>
                </TableCell>
                <TableCell>
                  <b>Chantier/ projet</b>
                </TableCell>
                <TableCell>
                  <b>Méthode</b>
                </TableCell>
                <TableCell align="right">
                  <b>Montant crédité</b>
                </TableCell>
                <TableCell align="right">
                  <b>Montant débité</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactionsRecentes &&
                transactionsRecentes
                  .sort((a, b) => {
                    const nameA = a.date;
                    const nameB = b.date;
                    if (nameA < nameB) {
                      return 1;
                    }
                    if (nameA > nameB) {
                      return -1;
                    }
                    return 0;
                  })
                  .sort((a, b) => {
                    const nameA = a.type;
                    const nameB = b.type;
                    if (a.date == b.date && nameA > nameB) {
                      return 1;
                    }
                    if (a.date == b.date && nameA < nameB) {
                      return -1;
                    }

                    return 0;
                  })
                  .map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell>{row.date}</TableCell>
                      <TableCell>
                        {factures &&
                        factures.filter(
                          (x) => x.numero.replace("RD", "") == row.libelle
                        )[0]
                          ? factures.filter(
                              (x) => x.numero.replace("RD", "") == row.libelle
                            )[0].infoAdd +
                            "/ " +
                            factures.filter(
                              (x) => x.numero.replace("RD", "") == row.libelle
                            )[0].projetNom
                          : row.libelle}
                      </TableCell>
                      <TableCell>{row.methode}</TableCell>
                      <TableCell align="right">
                        {row.type == "CR" ? row.montant : ""}
                      </TableCell>
                      <TableCell align="right">
                        {row.type == "DB" ? row.montant : ""}
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}
