import React, { useState } from "react";
import { Navigate, useParams, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { reset_password_confirm } from "../actions/auth";
import swal from "sweetalert";
import vial_mali_logo from "../../data_et_files/vial_mali_logo.png";
const ResetPasswordConfirm = ({ reset_password_confirm }) => {
  const [requestSent, setRequestSent] = useState(false);
  const [formData, setFormData] = useState({
    new_password: "",
    re_new_password: "",
  });
  const { uid, token } = useParams();

  const { new_password, re_new_password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();

    const uid_ = uid;
    const token_ = token;

    reset_password_confirm(uid_, token_, new_password, re_new_password);
    setRequestSent(true);
  };

  if (requestSent) {
    swal({
      text: "Votre mot de passe a ete modifie avec succes!",
      icon: "success",
      buttons: false,
    });

    setTimeout(function () {
      window.location.replace("/");
    }, 1500);
  }

  return (
    <div className="acceuil">
      <div className="w3-bar w3-indigo w3-center" style={{ height: "52px" }}>
        <NavLink to="/" style={{ textDecoration: "none" }}>
          <h3>
            <img
              src={vial_mali_logo}
              height={30}
              width={45}
              style={{ marginTop: "-7px" }}
            />{" "}
            &nbsp; SGVM <i class="fa fa-home" aria-hidden="true" />
          </h3>
        </NavLink>{" "}
      </div>
      <div className="w3-container w3-center w3-padding">
        <div className="w3-container margin-top">
          <form onSubmit={(e) => onSubmit(e)}>
            <div className="form-group">
              <input
                className="form-control"
                type="password"
                placeholder="Nouveau mot-de passe"
                name="new_password"
                value={new_password}
                onChange={(e) => onChange(e)}
                minLength="6"
                required
              />
            </div>
            <p></p>
            <div className="form-group">
              <input
                className="form-control"
                type="password"
                placeholder="Confirm nouveau mot de passe"
                name="re_new_password"
                value={re_new_password}
                onChange={(e) => onChange(e)}
                minLength="6"
                required
              />
            </div>
            <button className="w3-button w3-indigo" type="submit">
              Reinitialiser mot de passe
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { reset_password_confirm })(ResetPasswordConfirm);
