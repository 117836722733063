import { useState, useRef } from "react";
import { Outlet, useLoaderData, useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
export default function TransactionsListe() {
  const entity = useLoaderData().filter((x) => x.type == "R");

  const [listeEntity, setListeEntity] = useState(entity);

  const navigate = useNavigate();

  function selectionEntity(id) {
    navigate("" + id);
  }

  const toPrint = useRef(null);

  const handlePrint = useReactToPrint({
    documentTitle: "Liste de transactions",
    removeAfterPrint: true,
  });

  function search(event) {
    const searchKey = event.target.value.toLowerCase();
    if (searchKey)
      setListeEntity(
        entity.filter(
          (entity) =>
            entity.date.toLowerCase().includes(searchKey) ||
            entity.effectueParPrenom.toLowerCase().includes(searchKey) ||
            entity.effectueParNom.toLowerCase().includes(searchKey) ||
            entity.numero.toLowerCase().includes(searchKey)
        )
      );
    else setListeEntity(entity);
  }

  return (
    <div className="repertoire-employe-layout">
      <div className="the-employe-table w3-cell" style={{ width: "100%" }}>
        <button
          onClick={() => {
            handlePrint(null, () => toPrint.current);
          }}
          className="w3-btn w3-ripple w3-pale-blue w3-round w3-margin-bottom"
          style={{ width: "300px" }}
        >
          <i className="fa fa-print" aria-hidden="true"></i> Imprimer
        </button>
        <input
          onChange={search}
          placeholder={
            "Entrez la date de la facture ou le nom de l'agent commercial qui a généré la facture"
          }
          style={{ width: "100%", backgroundColor: "azure", height: "40px" }}
          autoFocus
        />
        <p></p>
        <div className="toPrint" ref={toPrint}>
          <div className="w3-card-4 w3-bar">
            <table className="w3-table-all w3-hoverable">
              <thead>
                <tr className="w3-light-grey">
                  <th>Ordre No.</th>
                  <th>Facture No.</th>
                  <th>Date de la facture</th>
                  <th>Client</th>
                  <th>Nom du chantier/ type de vente</th>
                  <th>Net à payer initial</th>
                  <th>Reste à payer</th>
                  {/* <th>État de la facture </th> */}
                  <th>Date d'échéance</th>
                  <th>Agent commercial</th>
                </tr>
              </thead>
              <tbody>
                {listeEntity
                  .sort((a, b) => {
                    const nameA = a.id; // ignore upper and lowercase
                    const nameB = b.id; // ignore upper and lowercase
                    if (nameA < nameB) {
                      return 1;
                    }
                    if (nameA > nameB) {
                      return -1;
                    }

                    // names must be equal
                    return 0;
                  })
                  .map((x) => {
                    return (
                      <tr key={x.id} onClick={() => selectionEntity(x.id)}>
                        <td>{x.id}</td>
                        <td>{x.numero.replace("RD", "")}</td>
                        <td>{x.date}</td>
                        <td>
                          {x.projet
                            ? x.clientEntreprise_ +
                              "/ " +
                              x.clientPrenom_ +
                              " " +
                              x.clientNom_
                            : x.clientEntreprise +
                              "/ " +
                              x.clientPrenom +
                              " " +
                              x.clientNom}
                        </td>
                        <td>{x.projetNom || "VENTE IMMEDIATE"}</td>
                        <td>{x.montantNet}</td>
                        <td>{x.restantAPayerCourant}</td>
                        <td>{x.dateEcheance}</td>

                        <td>{x.effectueParPrenom + " " + x.effectueParNom}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div
        className="the-employe-details w3-container w3-cell"
        style={{ width: "1000px" }}
      >
        <Outlet />
      </div>
    </div>
  );
}
