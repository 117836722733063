import { NavLink } from "react-router-dom";
import MenuButton from "../../components/MenuButton";

export default function VentesHome() {
  return (
    <div className="personnel-page">
      <div className="w3-row">
        <div className="w3-container">
          <header class="w3-container w3-theme w3-padding" id="myHeader">
            <div class="w3-center">
              <p class="w3-xlarge w3-animate-bottom">
                Veuillez selectionner parmi les options suivantes
              </p>
            </div>
          </header>

          <div class="w3-row-padding w3-center w3-margin-top">
            <MenuButton
              title={"Transactions de vente"}
              // desc={"Générer facture payable de vente"}
              icon={"fa fa-shopping-cart"}
              w3color={"w3-teal"}
              link={"transactions"}
              addToClass={"w3-margin-top"}
            />
            {/* <MenuButton
              title={"Transactions de vente"}
              desc={"Effectuer une vente et generer une facture payable"}
              icon={"fa fa-cart-arrow-down"}
              w3color={"w3-green"}
              link={"transactions"}
            /> */}

            {/* <MenuButton
              title={"Dévis"}
              desc={"Créer dévis"}
              icon={"fa fa-file-text-o"}
              w3color={"w3-orange"}
              link={"devis"}
            /> */}
            <MenuButton
              title={"Chantiers"}
              // desc={"Repertoire des chantiers"}
              icon={"fa fa-briefcase"}
              w3color={"w3-dark-gray"}
              link={"chantiers"}
              addToClass={"w3-margin-top"}
            />
            <MenuButton
              title={"Clients et comptes recevables"}
              // desc={"Repertoire des clients"}
              icon={"fa fa-user"}
              w3color={"w3-dark-gray"}
              link={"clients"}
              addToClass={"w3-margin-top"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
