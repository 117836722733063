import {
  NavLink,
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import useAPIData from "../../../functions/useAPIData";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import CarteEmploye from "../../../components/CarteEmploye";
import CarteEmployeVerso from "../../../components/CarteEmployeVerso";
import CarteEmployeTemp from "../../../components/CarteEmployeTemp";
// import deleteEmployeData from "../../../functions/personnel/deleteEmployeData";

export default function CarteDetails() {
  // const loc = useLocation().pathname

  const { numeroEmploye } = useParams();

  const employe = useLoaderData().filter(
    (obj) => obj.numeroEmploye == numeroEmploye
  )[0];

  const carteE = useAPIData("carteEmploye").filter(
    (x) => x.employe == numeroEmploye && x.indAffectationActive == "O"
  )[0];

  const toPrint = useRef(null);

  const handlePrint = useReactToPrint({
    documentTitle: "Fiche d'employé de" + employe.prenom + " " + employe.nom,
    removeAfterPrint: true,
  });
  const poste = useAPIData("poste").filter((obj) => obj.id == employe.poste)[0];
  const departement = useAPIData("departement").filter(
    (obj) => obj.id == employe.departement
  )[0];

  let display_employe_photo = null;

  if (employe.photo) {
    display_employe_photo = employe.photo.replace("media", "static/media");
  }

  return (
    <div className="theEmployeDetails">
      {carteE ? (
        ""
      ) : (
        <NavLink
          to={"attribuer-carte"}
          className="w3-btn w3-ripple w3-purple w3-round "
          style={{ width: "300px" }}
        >
          <i className="fa fa-id-card-o" aria-hidden="true"></i> Attribuer
          nouvelle carte
        </NavLink>
      )}

      {/* <NavLink
        to={"modifier-carte"}
        className="w3-btn w3-ripple w3-yellow w3-round w3-margin-left "
        style={{ width: "300px" }}
      >
        <i className="fa fa-pencil" aria-hidden="true"></i> Modifier carte
      </NavLink>

      <NavLink
        to={"annuler-carte"}
        className="w3-btn w3-ripple w3-pink w3-round w3-margin-left"
        style={{ width: "300px" }}
      >
        <i className="fa fa-times-circle" aria-hidden="true"></i> Annuler carte
      </NavLink> */}

      <div className="w3-row">
        <div className="toPrint" ref={toPrint}>
          {employe.typeEmploi == "P" ? (
            <CarteEmploye
              photo={display_employe_photo}
              numero={numeroEmploye}
              prenom={employe && employe.prenom}
              nom={employe && employe.nom}
              dateEmbauche={employe && employe.dateEmbauche}
              departement={departement && departement.nom}
              poste={poste && poste.nom}
              telephone1={employe && employe.telephone}
              telephone2={employe && employe.telephone2}
            />
          ) : (
            <CarteEmployeTemp />
          )}
        </div>
        <div className="w3-quarter w3-margin-left">
          {carteE ? (
            <div>
              <p>Numero de carte: {carteE.numeroCarte}</p>

              {/* <p>
                <Barcode
                  value={numeroCarte.numeroCarte}
                  options={{ format: "ean13" }}
                  renderer="image"
                  style={{ width: 500 }}
                />
              </p> */}
            </div>
          ) : (
            <p>Pas de carte assignee et active</p>
          )}
        </div>
        <div>
          <CarteEmployeVerso numeroCarte={carteE && carteE.numeroCarte} />
        </div>
      </div>
    </div>
  );
}
