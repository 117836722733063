import { useState } from "react";
import { Link, Navigate, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { login } from "../actions/auth";
import swal from "sweetalert";
import vial_mali_logo from "../../data_et_files/vial_mali_logo.png";

const Login = ({ login, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();

    login(email, password);
  };

  // Is the user authenticated?
  //Redirect to home page
  if (isAuthenticated) {
    swal({
      text: "Connecté!",
      icon: "success",
      buttons: false,
    });

    setTimeout(function () {
      window.location.replace("/");
    }, 1500);
  }

  return (
    <div className="acceuil">
      <div className="w3-bar w3-indigo w3-center" style={{ height: "52px" }}>
        <NavLink to="/" style={{ textDecoration: "none" }}>
          <h3>
            <img
              src={vial_mali_logo}
              height={30}
              width={45}
              style={{ marginTop: "-7px" }}
            />{" "}
            &nbsp; SGVM <i class="fa fa-home" aria-hidden="true" />
          </h3>
        </NavLink>
      </div>
      <div className="w3-container w3-center w3-padding">
        <div className="w3-container margin-top">
          <h1>Se connecter</h1>
          <p>Entrez vos informations d'acces ci-dessous</p>
          <form onSubmit={(e) => onSubmit(e)}>
            <div className="form-group">
              <input
                className="form-control"
                type="email"
                placeholder="Email"
                name="email"
                value={email}
                onChange={(e) => onChange(e)}
                required
              />
            </div>
            <p></p>
            <div className="form-group">
              <input
                className="form-control"
                type="password"
                placeholder="Mot de passe"
                name="password"
                value={password}
                onChange={(e) => onChange(e)}
                minLength="6"
                required
              />
            </div>
            <p></p>
            <button className="w3-button w3-indigo w3-round" type="submit">
              Connexion
            </button>
          </form>
          <p className="mt-3">
            Vous n'avez pas de compte? Soumettre une requete d'access en{" "}
            <Link to="/signup">cliquant ici</Link>
          </p>
          <p className="mt-3">
            Vous avez oublie votre mot de passe? Reinitialiser votre mot de
            passe en <Link to="/reset-password">cliquant ici</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(Login);
