import swal from "sweetalert";

export default async function sendCarteFormData(
  formData,
  endpoint,
  method,
  new_or_existing
) {
  //Create csrftoken - Required for DJANGO!
  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }

  const csrftoken = getCookie("csrftoken");

  let response;
  let result;

  try {
    response = await fetch(endpoint, {
      method: method,
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken,
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
      body: JSON.stringify(formData),
    });

    result = await response.json();
  } catch (error) {
    swal({
      text: "Erreur du serveur",
      icon: "error",
      buttons: false,
    });
    setTimeout(function () {
      window.location.replace(
        process.env.REACT_APP_THE_HOST + "/personnel/carteEmploye/"
      );
    }, 1500);
  }

  if (!response.ok) {
    swal({
      text: "Erreur dans le formulaire",
      icon: "error",
      buttons: false,
    });
    setTimeout(function () {
      window.location.replace(
        process.env.REACT_APP_THE_HOST + "/personnel/employes/"
      );
    }, 1500);
  } else if (response.ok && new_or_existing == "new") {
    swal({
      text: "La carte a ete assigne avec SUCCES!",
      icon: "success",
      buttons: false,
    });
    setTimeout(function () {
      window.location.replace(
        process.env.REACT_APP_THE_HOST +
          "/personnel/employes/" +
          result.numeroEmploye +
          "/carte-employe"
      );
    }, 1500);
  } else if (response.ok && new_or_existing == "existing") {
    swal({
      text: "La carte a ete mise a jour avec SUCCES! ",
      icon: "success",
      buttons: false,
    });
    setTimeout(function () {
      window.location.replace(
        process.env.REACT_APP_THE_HOST +
          "/personnel/employes/" +
          result.numeroEmploye
      );
    }, 1500);
  }
}
