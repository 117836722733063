import React, { useState, useEffect } from "react";
import { Link, NavLink, Outlet } from "react-router-dom";
import { connect } from "react-redux";
import { checkAuthenticated, load_user } from "../authsys/actions/auth";

function RootLayout(props, { user }) {
  useEffect(() => {
    props.checkAuthenticated();
    props.load_user();
  }, []);

  const [theSidebarContentStyle, settheSidebarContentStyle] = useState({
    display: "none",
  });

  const [theContentStyle, setTheContentStyle] = useState({
    marginLeft: "0%",
    marginBottom: "20px",
  });

  const [theNavButtonStyle, setTheNavButtonStyle] = useState({
    display: "inline-block",
    position: "relative",
  });

  function openHamburger() {
    settheSidebarContentStyle({
      display: "block",
      width: "15%",
    });
    setTheContentStyle({
      marginLeft: "15%",
    });
    setTheNavButtonStyle({
      display: "none",
    });
  }

  function closeHamburger() {
    settheSidebarContentStyle({
      display: "none",
    });
    setTheContentStyle({
      marginLeft: "0%",
    });
    setTheNavButtonStyle({
      display: "inline-block",
    });
  }

  return (
    <div className="root-layout">
      <div className="theContent" style={theContentStyle}>
        <Outlet />
      </div>
      {props.children}
    </div>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

export default connect(null, { checkAuthenticated, load_user })(RootLayout);
