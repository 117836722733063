import {
  NavLink,
  useLoaderData,
  useLocation,
  useParams,
} from "react-router-dom";
import useAPIData from "../../../functions/useAPIData";
import {
  Page,
  Text,
  View,
  Document,
  Image,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";

import logoVial from "../../../data_et_files/vial_mali_logo.png";
import { useState } from "react";

function sortFunction(v1, v2) {
  return v1.numero - v2.numero;
}

const formatCurrency = (number) => {
  // Add thousands separator
  const formattedNumber = number
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    .replace(".", ",");

  // Format the number as a currency string
  return `${formattedNumber}`;
};

export default function TransactionsDetails() {
  // const loc = useLocation().pathname

  const { id } = useParams();

  const entity = useLoaderData();

  const thisEntity = entity.filter((obj) => obj.id == id)[0];

  const dernierPaiement = useAPIData("transactionFinanciere")
    .filter((x) => x.destinationNom == thisEntity.numero)
    .slice(-1)[0];

  // Create styles
  const styles = StyleSheet.create({
    page: {
      backgroundColor: "white",
      paddingVertical: "30px",
      paddingHorizontal: "40px",
    },
    footer: {
      position: "absolute",
      left: 35,
      bottom: 10,
      width: "100%",
      color: "red",
      fontSize: "10px",
      // textAlign: "center",
    },
    table: {
      width: "100%",
      fontSize: "15px",
    },
    row: {
      display: "flex",
      flexDirection: "row",
    },
    rowT: {
      display: "flex",
      flexDirection: "row",
      borderTop: "1px solid #EEE",
      paddingTop: 8,
      paddingBottom: 8,
    },
    rowI: {
      display: "flex",
      flexDirection: "row",
      borderTop: "1px solid #EEE",
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: "15px",
    },
    header: {
      borderTop: "none",
    },
    bold: {
      fontWeight: "bold",
    },
    row15: {
      width: "15%",
      textAlign: "center",
    },
    row15M: {
      width: "15%",
      textAlign: "right",
    },
    row40: {
      width: "40%",
    },
    row50: {
      width: "50%",
    },
    row85: {
      width: "85%",
    },
  });

  // Create Document Component
  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={[styles.table, styles.row]}>
          <View style={styles.row50}>
            <Image
              src={logoVial}
              style={{
                width: "100px",
                height: "50px",
                alignSelf: "flex-end",
                marginRight: "40px",
              }}
            />
          </View>
          <Text
            style={[
              styles.row50,
              { fontSize: "50px", marginLeft: "-37px", marginBottom: "10px" },
            ]}
          >
            Vial-Mali
          </Text>
        </View>
        <Text>____________________________________________________</Text>
        <Text
          style={{
            marginTop: "10px",
            backgroundColor: "grey",
            color: "white",
            fontSize: "20px",
          }}
        >
          Facture No. {thisEntity.numero.replace("RD", "")}
        </Text>
        <Text style={{ marginTop: "10px" }}>
          Client:{" "}
          {thisEntity.projet
            ? thisEntity.clientEntreprise_ +
              "/ " +
              thisEntity.clientPrenom_ +
              " " +
              thisEntity.clientNom_
            : thisEntity.clientEntreprise +
              "/ " +
              thisEntity.clientPrenom +
              " " +
              thisEntity.clientNom}
        </Text>
        <Text style={{ marginTop: "10px" }}>
          {thisEntity.projet
            ? "Chantier: " + thisEntity.projetNom
            : "VENTE IMMÉDIATE"}
        </Text>
        {/* <Text style={{ marginTop: "10px" }}>
          Doit:{" "}
          {(thisClient && thisClient.nomEntreprise) ||
            (thisClient && thisClient.prenom + " " + thisClient.nom)}
        </Text> */}
        <Text style={{ alignSelf: "flex-end", marginTop: "10px" }}>
          Date de la facture: {thisEntity.date}
        </Text>
        <Text style={{ alignSelf: "flex-end", marginTop: "10px" }}>
          Agent commercial: {thisEntity.effectueParPrenom}{" "}
          {thisEntity.effectueParNom}
        </Text>
        <Text style={{ alignSelf: "flex-end", marginTop: "10px" }}>
          Date du dernier paiement:{" "}
          {!dernierPaiement &&
          thisEntity.montantNet != thisEntity.restantAPayerCourant
            ? thisEntity.date
            : !dernierPaiement &&
              thisEntity.montantNet == thisEntity.restantAPayerCourant
            ? ""
            : dernierPaiement.date}
        </Text>
        <Text style={{ marginTop: "10px", fontSize: "15px" }}>
          Détails: {thisEntity.infoAdd}
        </Text>
        <Text>____________________________________________________</Text>
        <View style={[styles.table, styles.rowT]}>
          <Text
            style={[
              styles.row85,
              { fontSize: "20px", textAlign: "left", marginTop: "10px" },
            ]}
          >
            NET A PAYER INITIAL
          </Text>
          <Text style={(styles.row15M, { fontSize: "20px" })}>
            {formatCurrency(thisEntity.montantNet)} FCFA
          </Text>
        </View>
        <View style={[styles.table, styles.rowT]}>
          <Text
            style={[
              styles.row85,
              { fontSize: "20px", textAlign: "left", marginTop: "10px" },
            ]}
          >
            MONTANT DERNIER PAIEMENT
          </Text>
          <Text style={(styles.row15M, { fontSize: "20px" })}>
            {!dernierPaiement
              ? formatCurrency(
                  thisEntity.montantNet - thisEntity.restantAPayerCourant
                )
              : formatCurrency(dernierPaiement.montant)}{" "}
            FCFA
          </Text>
        </View>
        <View style={[styles.table, styles.rowT]}>
          <Text
            style={[
              styles.row85,
              { fontSize: "20px", textAlign: "left", marginTop: "10px" },
            ]}
          >
            TOTAL PAIEMENTS EFFECTUES
          </Text>
          <Text style={(styles.row15M, { fontSize: "20px", color: "blue" })}>
            {formatCurrency(
              Math.abs(thisEntity.montantNet - thisEntity.restantAPayerCourant)
            )}{" "}
            FCFA
          </Text>
        </View>
        <View style={[styles.table, styles.rowT]}>
          <Text style={[styles.row85, { fontSize: "20px", textAlign: "left" }]}>
            TOTAL RESTANT A PAYER
          </Text>
          <Text style={(styles.row15M, { fontSize: "25px", color: "red" })}>
            {formatCurrency(thisEntity.restantAPayerCourant)} FCFA
          </Text>
        </View>
        <View style={[styles.table, styles.rowT]}>
          <Text style={[styles.row85, { fontSize: "20px", textAlign: "left" }]}>
            ÉTAT DE LA FACTURE
          </Text>
          <Text
            style={
              (styles.row15M,
              {
                fontSize: "20px",
                backgroundColor:
                  thisEntity.restantAPayerCourant <= 0 ? "turquoise" : "yellow",
              })
            }
          >
            {thisEntity.restantAPayerCourant <= 0 ? "REGLÉE" : "Non-reglée"}
          </Text>
        </View>
        <View
          style={[
            styles.table,
            styles.row,
            { textAlign: "center", marginTop: "50px", fontSize: "25px" },
          ]}
        >
          <Text style={styles.row50}>Le client</Text>
          <Text style={styles.row50}>Le fournisseur</Text>
        </View>
        <View style={styles.footer}>
          <Text style={{ textAlign: "center" }}>
            RUE TITI NIARE-PN°1141- RC 2483 - TEL : 66-74-76-28 / 76 40 34 81 /
            BCI 021109360009
          </Text>
          <Text style={{ textAlign: "center" }}>
            BMS 000307 011 01- 50 BDM 020401005720-10 FISCAL 082 200 345 J
            BAMAKO
          </Text>
        </View>
      </Page>
    </Document>
  );

  return (
    <div className="theEmployeDetails">
      {thisEntity.restantAPayerCourant > 0 ? (
        <NavLink
          to="paiement"
          className="w3-btn w3-ripple w3-purple w3-round"
          style={{ width: "300px" }}
          target="_blank"
        >
          <i class="fa fa-money" aria-hidden="true"></i> Effectuer paiement
          contre facture
        </NavLink>
      ) : (
        ""
      )}

      <hr />

      <div className="w3-display-container">
        <div
          className="w3-container w3-display-topmiddle"
          style={{ width: "100%" }}
        >
          <PDFViewer style={{ width: "100%", minHeight: "100vh" }}>
            <MyDocument />
          </PDFViewer>
        </div>
      </div>
    </div>
  );
}
