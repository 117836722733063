import { useEffect, useState } from "react";
import { NavLink, useLoaderData, useParams } from "react-router-dom";
import FormTitle from "../../../components/FormTitle";
import sendRecetteDepenseFormData from "../../../functions/ventes/sendRecetteDepenseFormData";
import { connect } from "react-redux";
import useAPIData from "../../../functions/useAPIData";

function TransactionsAChantierForm({ user }) {
  const { id } = useParams();
  const thisEntity = useLoaderData().filter((obj) => obj.id == id)[0];

  const fournisseurs = useAPIData("fournisseur");

  let initValue = {
    type: "D",
    montantBrut: "-9999999", //Placeholder - improve in v2
    etatCourant: "N",
    projet: id,
  };

  const [formData, setFormData] = useState(initValue);

  function handleChange(event) {
    const { name, value } = event.target;

    setFormData((prevValues) => ({ ...prevValues, [name]: value }));
  }

  function handleSubmit(event) {
    event.preventDefault();

    console.log(formData);

    sendRecetteDepenseFormData(
      {
        effectuePar: user && user.employeNumber,
        restantAPayerCourant: formData.montantNet,
        ...formData,
      },
      process.env.REACT_APP_THE_HOST + "/api/recetteDepense/",
      "POST",
      "new",
      "D"
    );
  }
  return (
    <div className="repertoire-employe-layout">
      <div style={{ width: "800px" }}>
        <form method="post" onSubmit={handleSubmit}>
          <FormTitle
            title={
              "Nouvelle facture de dépense relié au chantier " + thisEntity.nom
            }
          />
          <div className="w3-panel w3-light-gray">
            <h3>
              <p>
                Veuillez fournir les infos ci-dessous{" "}
                <span style={{ color: "red" }}>(requis)</span>
              </p>
            </h3>
          </div>

          <div className="w3-row w3-section">
            <div
              className="w3-quarter w3-button"
              style={{ pointerEvents: "none" }}
            >
              <b>
                <span style={{ color: "red" }}>Date</span>
              </b>
            </div>
            <div className="w3-threequarter">
              <input
                className="w3-input w3-border w3-sand"
                name="date"
                type="date"
                value={formData.date}
                onChange={handleChange}
                required={true}
              />
            </div>
          </div>

          <div className="w3-row w3-section">
            <div
              className="w3-quarter w3-button"
              style={{ pointerEvents: "none" }}
            >
              <b>
                <span style={{ color: "red" }}>Nature de la dépense</span>
              </b>
            </div>
            <div className="w3-threequarter w3-container">
              <select
                name="nature_d"
                value={formData.nature_d}
                onChange={handleChange}
                required
                style={{ width: "50%", marginTop: "7.5px" }}
                className="w3-yellow"
              >
                <option value={""}>selectionner</option>
                <option value={"DO"}>Dépenses opérationnelles</option>
                <option value={"CM"}>Commande fournisseur</option>
                <option value={"PS"}>Prestation sous-traitant</option>
                <option value={"AU"}>Autres dépenses/ divers</option>
              </select>
            </div>
          </div>

          <div className="w3-row w3-section">
            <div
              className="w3-quarter w3-button"
              style={{ pointerEvents: "none" }}
            >
              <b>
                <span style={{ color: "red" }}>Fournisseur</span>
              </b>
            </div>
            <div className="w3-threequarter w3-container">
              <select
                name="fournisseur"
                value={formData.fournisseur}
                onChange={handleChange}
                required
                style={{ width: "50%", marginTop: "7.5px" }}
                className="w3-yellow"
              >
                <option value={""}>selectionner</option>
                {fournisseurs &&
                  fournisseurs.map((x) => (
                    <option key={x.id} value={x.id}>
                      {x.nomEntreprise + "/ " + x.prenom + " " + x.nom}
                    </option>
                  ))}
              </select>{" "}
              <NavLink
                to={
                  process.env.REACT_APP_THE_HOST +
                  "/depenses/fournisseurs/nouveau-fournisseur"
                }
                id="theNavLinkMenu"
                // className="w3-bar-item w3-button w3-ripple w3-orange w3-round w3-margin-left w3-margin-top"
                target="_blank"
              >
                <em>
                  <i className="fa fa-plus-circle" aria-hidden="true" /> Ajouter
                  nouveau fournisseur
                </em>
              </NavLink>
            </div>
          </div>

          <div className="w3-row w3-section">
            <div
              className="w3-quarter w3-button"
              style={{ pointerEvents: "none" }}
            >
              <b>
                <span style={{ color: "red" }}>Net a payer</span>
              </b>
            </div>
            <div className="w3-threequarter">
              <input
                className="w3-input w3-border w3-sand"
                name="montantNet"
                type="number"
                value={formData.montantNet}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="w3-row w3-section">
            <div
              className="w3-quarter w3-button"
              style={{ pointerEvents: "none" }}
            >
              <b>
                <span style={{ color: "red" }}>À payer avant</span>
              </b>
            </div>
            <div className="w3-threequarter">
              <input
                className="w3-input w3-border w3-sand"
                name="dateEcheance"
                type="date"
                value={formData.dateEcheance}
                onChange={handleChange}
                required={true}
              />
            </div>
          </div>

          <div className="w3-row w3-section">
            <div
              className="w3-quarter w3-button"
              style={{ pointerEvents: "none" }}
            >
              <b>Observations</b>
            </div>
            <div className="w3-threequarter">
              <textarea
                className="w3-input w3-border w3-sand"
                name="infoAdd"
                type="text"
                value={formData.infoAdd}
                onChange={handleChange}
              />
            </div>
          </div>

          <button
            className="w3-btn w3-ripple w3-green w3-round"
            style={{ width: "500px" }}
            name="action"
            type="submit"
          >
            Soumettre
          </button>
          <button
            className="w3-btn w3-ripple w3-light-grey w3-round w3-margin-left"
            style={{ width: "284px" }}
            onClick={() =>
              window.location.replace(
                process.env.REACT_APP_THE_HOST + "/ventes/transactions/"
              )
            }
          >
            Annuler
          </button>
        </form>

        <p></p>

        <p></p>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(TransactionsAChantierForm);
