import Barcode from "react-jsbarcode";
import logo from "../data_et_files/vial_mali_logo.png";

export default function CarteEmployeVerso({ numeroCarte }) {
  return (
    <div className="w3-card-4 w3-bar w3-margin-top w3-twothird w3-padding-large w3-white">
      <div className="w3-bar-item w3-cell w3-cell-bottom">
        <img
          src={logo}
          style={{
            width: "85%",
            height: "300px",
            marginTop: "30px",
            marginLeft: "200px",
            opacity: 0.2,
          }}
          alt="#"
        />

        <div
          style={{
            width: "100%",
            height: "200px",
            marginTop: "20px",
            marginLeft: "380px",
          }}
        >
          <h3>VIAL-MALI - Vitrerie et Aluminium du Mali</h3>
          <h4>FISCAL 082 200 345 J BAMAKO</h4>
          <h4>Immeuble 5 étages - Banankabougou, Bamako, Mali</h4>
          <h4>Tel : +223 66 74 76 28 / 76 40 34 81 / 70 72 43 22</h4>
          <h4>Email: administration@vialmali.ml</h4>
        </div>
        <div
          style={{
            width: "100px",
            // height: "200px",
            marginTop: "10px",
            marginLeft: "400px",
            marginBottom: "30px",
          }}
        >
          <Barcode
            value={numeroCarte}
            options={{ format: "ean13" }}
            renderer="image"
            style={{ width: "350px" }}
          />
        </div>
      </div>
    </div>
  );
}
