import photoTemp from "../data_et_files/T_Temporaire.png";

export default function CarteEmployeTemp() {
  return (
    <div className="w3-card-4 w3-bar w3-margin-top w3-twothird w3-padding-large w3-white">
      <div className="w3-bar w3-deep-purple w3-padding w3-round w3-center">
        <h1>Carte VIAL</h1>
      </div>
      <div className="w3-container w3-center w3-padding-16">
        <img
          src={photoTemp}
          className="w3-hide-small w3-border w3-circle"
          style={{ width: "500px", height: "500px" }}
          alt="#"
        />
      </div>
    </div>
  );
}
