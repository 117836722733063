import generique_employe from "../data_et_files/generique_employe.png";
import logo from "../data_et_files/vial_mali_logo.png";

export default function CarteEmploye({
  prenom,
  nom,
  numero,
  dateEmbauche,
  departement,
  poste,
  photo,
  telephone1,
  telephone2,
}) {
  return (
    <div className="w3-card-4 w3-bar w3-margin-top w3-twothird w3-padding-large w3-white">
      <div
        className="w3-bar w3-deep-purple w3-padding w3-round w3-center"
        style={{ height: "100px" }}
      >
        <h1>Carte VIAL</h1>
      </div>
      <div className="w3-bar-item w3-container w3-cell w3-center w3-padding-16">
        <img
          src={photo ? photo : generique_employe}
          className="w3-hide-small w3-border w3-circle"
          style={{ width: "500px", height: "500px" }}
          alt="#"
        />
        <h1>
          <strong>
            {prenom} {nom}
          </strong>
        </h1>
      </div>

      <div className="w3-bar-item w3-container w3-cell w3-cell-middle w3-padding-64 w3-padding-large">
        <h1>
          Numero d'employé:{" "}
          <b>
            <em>{numero}</em>
          </b>
        </h1>

        <h1>
          Poste:{" "}
          <b>
            <em>{poste}</em>
          </b>
        </h1>

        <h1>
          Département:{" "}
          <b>
            <em>{departement}</em>
          </b>
        </h1>

        <h1>
          Téléphone(s):{" "}
          <b>
            <em>
              {telephone1} {telephone2 ? "/ " + telephone2 : ""}
            </em>
          </b>
        </h1>
        <h1>
          Employé depuis:{" "}
          <b>
            <em>{dateEmbauche.substring(0, 4)}</em>
          </b>
        </h1>
        <br />
      </div>

      {/* <div className="w3-bar-item w3-cell w3-cell-bottom">
        <img
          src={logo}
          style={{
            width: "100%",
            height: "250px",
            marginTop: "70px",
            marginLeft: "110px",
            opacity: 0.1,
          }}
          alt="#"
        />
      </div> */}
    </div>
  );
}
