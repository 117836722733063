import { useEffect, useState, useRef, useMemo } from "react";
import { Outlet, useLoaderData, useNavigate } from "react-router-dom";
import useAPIData from "../../../functions/useAPIData";
// import Table from "../../components/ReactTable"
import { useReactToPrint } from "react-to-print";
export default function EmployeListe() {
  const employes = useLoaderData();

  const postes = useAPIData("poste");
  const departements = useAPIData("departement");

  const toPrint = useRef(null);

  const handlePrint = useReactToPrint({
    documentTitle: "Tous les employes",
    removeAfterPrint: true,
  });

  function getPoste(posteId) {
    const thisPoste = postes.filter((x) => x.id == posteId)[0];

    return thisPoste && thisPoste.nom;
  }

  function getDepartment(departementId) {
    const thisDepartment = departements.filter((x) => x.id == departementId)[0];

    return thisDepartment && thisDepartment.nom;
  }

  function getResponsable(responsableId) {
    const thisResponsable = employes.filter(
      (x) => x.numeroEmploye == responsableId
    )[0];

    return (
      thisResponsable && thisResponsable.prenom + " " + thisResponsable.nom
    );
  }

  const [listeEmployes, setListeEmploye] = useState(employes);

  // const [selectedEmploye,setSelectedEmploye] = useState("")

  const navigate = useNavigate();

  function selectionEmploye(numEmp) {
    // setSelectedEmploye(numEmp)
    navigate(numEmp);
  }

  function search(event) {
    const searchKey = event.target.value.toLowerCase();
    // console.log(searchKey)
    if (searchKey)
      setListeEmploye(
        employes.filter(
          (employe) =>
            employe.prenom.toLowerCase().includes(searchKey) ||
            employe.nom.toLowerCase().includes(searchKey)
        )
      );
    else setListeEmploye(employes);
  }

  return (
    <div className="repertoire-employe-layout">
      <div className="the-employe-table w3-cell" style={{ width: "100%" }}>
        <button
          onClick={() => {
            handlePrint(null, () => toPrint.current);
          }}
          className="w3-btn w3-ripple w3-pale-blue w3-round w3-margin-bottom"
          style={{ width: "300px" }}
        >
          <i className="fa fa-print" aria-hidden="true"></i> Imprimer
        </button>
        <input
          onChange={search}
          placeholder={"Recherchez employé par nom ou prénom"}
          style={{ width: "100%" }}
        />
        <p></p>

        <div className="toPrint" ref={toPrint}>
          <table className="w3-table-all w3-hoverable">
            <thead>
              <tr className="w3-light-grey">
                <th>Prénom</th>
                <th>Nom</th>
                <th>Poste</th>
                <th>Département</th>
                <th>Responsable</th>
                <th>Téléphone</th>
              </tr>
            </thead>
            <tbody>
              {listeEmployes.map((employe) => (
                <tr
                  key={employe.id}
                  onClick={() => selectionEmploye(employe.numeroEmploye)}
                >
                  <td>{employe.prenom}</td>
                  <td>{employe.nom}</td>
                  <td>{getPoste(employe.poste)}</td>
                  <td>{getDepartment(employe.departement)}</td>
                  <td>{getResponsable(employe.responsable)}</td>
                  <td>{employe.telephone}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div
        className="the-employe-details w3-container w3-cell"
        style={{ width: "1000px" }}
      >
        <Outlet />
      </div>
    </div>
  );
}
