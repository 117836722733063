import { useEffect, useState, useMemo } from "react";
import {
  Link,
  NavLink,
  Outlet,
  useLoaderData,
  useNavigate,
} from "react-router-dom";
// import Table from "../../components/ReactTable"

export default function FounisseurLayout() {
  const navigate = useNavigate();
  return (
    <div className="repertoire-employes-layout">
      <div className="w3-pannel w3-gray">
        <div className="w3-bar">
          <h3 className="w3-bar-item w3-text-white">
            <strong>
              Fournisseurs et créanciers{" "}
              <i class="fa fa-user" aria-hidden="true" />
            </strong>
          </h3>
          <NavLink
            to=""
            id="theNavLinkMenu"
            className="w3-bar-item w3-button w3-ripple w3-yellow w3-round w3-margin-left w3-margin-top"
          >
            <i className="fa fa-book" aria-hidden="true" /> Repertoire des
            fournisseurs et créanciers
          </NavLink>
          <button
            onClick={() => navigate("nouveau-fournisseur")}
            id="theNavLinkMenu"
            className="w3-bar-item w3-button w3-ripple w3-indigo w3-round w3-margin-left w3-margin-top"
          >
            <i className="fa fa-plus-circle" aria-hidden="true" /> Nouveau
            fournisseur/ créancier
          </button>

          <NavLink
            to="/depenses"
            id="theNavLinkMenu"
            className="w3-bar-item w3-button w3-ripple w3-light-grey w3-round w3-margin-left w3-margin-top"
          >
            <i className="fa fa-chevron-circle-left" aria-hidden="true" />{" "}
            Retour au menu Dépenses opérationelles et achats
          </NavLink>
        </div>
      </div>
      <div className="w3-container w3-padding">
        <div className="the-content">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
