import { Outlet } from "react-router-dom";
import { connect } from "react-redux";
import NavbarModule from "../../components/NavbarModule";
import errorBackToAcceuil from "../../functions/errorBackToAcceuil";
import LoadingSpinner from "../../components/LoadingSpinner";
function OperationsLayout({ user }) {
  return !user ? (
    <LoadingSpinner color="blue" show={true} />
  ) : user.accessOperations < 1 ? (
    errorBackToAcceuil()
  ) : (
    <div className="personnel-layout">
      <NavbarModule
        module="Opérations"
        w3color={"w3-pink"}
        icon={"fa fa-cogs"}
      />

      <Outlet />
    </div>
  );
  // ) : (
  //   errorBackToAcceuil()
  // );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

export default connect(mapStateToProps)(OperationsLayout);
