// import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

//AUTH SYSTEM
import Login from "./authsys/containers/Login";
import Signup from "./authsys/containers/Signup";
import Activate from "./authsys/containers/Activate";
import ResetPassword from "./authsys/containers/ResetPassword";
import ResetPasswordConfirm from "./authsys/containers/ResetPasswordConfirm";

import { Provider } from "react-redux";
import store from "./store";

//APPLICATION
//layouts
import RootLayout from "./layouts/RootLayout";

import VentesLayout from "./layouts/ventes/VentesLayout";
import ChantierLayout from "./layouts/ventes/ChantierLayout";
import ClientLayout from "./layouts/ventes/ClientLayout";
import TransactionLayout from "./layouts/ventes/TransactionLayout";

import OperationsLayout from "./layouts/operations/OperationsLayout";
import StockLayout from "./layouts/operations/StockLayout";
import ArticleLayout from "./layouts/operations/ArticleLayout";
import MagasinLayout from "./layouts/operations/MagasinLayout";

import PersonnelLayout from "./layouts/personnel/PersonnelLayout";
import EmployeLayout from "./layouts/personnel/EmployeLayout";

//pages
import Acceuil from "./pages/acceuil/Acceuil";
// import Login from "./pages/auth/Login";
// import Profile from "./pages/auth/Profile";

//---ventes
import VentesHome from "./pages/ventes/VentesHome";
import ChantierListe from "./pages/ventes/chantiers/ChantierListe";
import ChantierDetails from "./pages/ventes/chantiers/ChantierDetails";
import ChantierForm from "./pages/ventes/chantiers/ChantierForm";
import ClientListe from "./pages/ventes/clients/ClientListe";
import ClientDetails from "./pages/ventes/clients/ClientDetails";
import ClientForm from "./pages/ventes/clients/ClientForm";
import TransactionsListe from "./pages/ventes/transactions/TransactionsListe";
import TransactionsDetails from "./pages/ventes/transactions/TransactionsDetails";

//---operations
import OperationsHome from "./pages/operations/OperationsHome";
import StockListe from "./pages/operations/stock/StockListe";
import StockDetails from "./pages/operations/stock/StockDetails";

import ArticleForm from "./pages/operations/articles/ArticleForm";
import ArticlePhotoForm from "./pages/operations/articles/ArticlePhotoForm";
import ArticleListe from "./pages/operations/articles/ArticleListe";
import ArticleDetails from "./pages/operations/articles/ArticleDetails";

import MagasinForm from "./pages/operations/magasin/MagasinForm";
import MagasinPhotoForm from "./pages/operations/magasin/MagasinPhotoForm";
import MagasinListe from "./pages/operations/magasin/MagasinListe";
import MagasinDetails from "./pages/operations/magasin/MagasinDetails";

//---personnel
import PersonnelHome from "./pages/personnel/PersonnelHome";
import EmployeForm from "./pages/personnel/employes/EmployeForm";
import EmployePhotoForm from "./pages/personnel/employes/EmployePhotoForm";
import EmployeListe from "./pages/personnel/employes/EmployeListe";
import EmployeDetails from "./pages/personnel/employes/EmployeDetails";
import CarteForm from "./pages/personnel/cartesEmployes/CarteForm";

import AchatsLayout from "./layouts/achats/AchatsLayout.js";
import AchatsHome from "./pages/achats/AchatsHome.js";
import TransactionALayout from "./layouts/achats/TransactionALayout.js";
import TransactionFinAchatsForm from "./pages/finances/transactions/TransactionFinAchatsForm.js";
import FournisseurLayout from "./layouts/achats/FournisseurLayout.js";
import FournisseurListe from "./pages/achats/fournisseurs/FournisseurListe.js";
import FournisseurDetails from "./pages/achats/fournisseurs/FournisseurDetails.js";
import FournisseurForm from "./pages/achats/fournisseurs/FournisseurForm.js";
//components

//functions and hooks
import { recetteDepenseLoader } from "./functions/ventes/recetteDepenseLoader";
import { clientLoader } from "./functions/ventes/clientLoader.js";
import { stockLoader } from "./functions/operations/stockLoader";
import { articleLoader } from "./functions/operations/articleLoader";
import { magasinLoader } from "./functions/operations/magasinLoader";
import { employeLoader } from "./functions/personnel/employeLoader";
import { projetLoader } from "./functions/ventes/projetLoader";
import { carteEmployeLoader } from "./functions/personnel/carteEmployeLoader";
import { cartePhysiqueLoader } from "./functions/personnel/cartePhysiqueLoader";
import { authorizedUsersOnlyLoader } from "./functions/authotizedUsersOnlyLoader";
import { transactionFinLoader } from "./functions/finances/transactionFinLoader";
import { sourceFinLoader } from "./functions/finances/sourceFinLoader";
//state management
import { DevisArticlesContextProvider } from "./context/DevisArticlesContext";
import CarteDetails from "./pages/personnel/cartesEmployes/CarteDetails";
import CarteListe from "./pages/personnel/cartesEmployes/CarteListe";
import CarteEmployeLayout from "./layouts/personnel/CarteEmployeLayout";
import AnalyticsLayout from "./layouts/analytics/AnalyticsLayout";
import AnalyticsHome from "./pages/analytics/AnalyticsHome";
import AnalyticsStock from "./pages/analytics/AnalyticsStock";
import AnalyticsPersonnel from "./pages/analytics/AnalyticsPersonnel";
import AnalyticsStockTxns from "./pages/analytics/AnalyticsStockTxns";
import { stockTxnsLoader } from "./functions/operations/stockTxnsLoader";
import StockRetirer from "./pages/operations/stock/StockRetirer";
import StockAjouter from "./pages/operations/stock/StockAjouter";
import FinancesLayout from "./layouts/finances/FinancesLayout";
import FinancesHome from "./pages/finances/FinancesHome";
import TransactionFinLayout from "./layouts/finances/TransactionFinLayout";
import TransactionFinListe from "./pages/finances/transactions/TransactionFinListe";
import TransactionFinForm from "./pages/finances/transactions/TransactionFinForm";
import CaissesLayout from "./layouts/finances/CaissesLayout";
import CaissesListe from "./pages/finances/caisses/CaissesListe";
import CaissesDetails from "./pages/finances/caisses/CaissesDetails.js";
import BanquesLayout from "./layouts/finances/BanquesLayout";
import BanquesListe from "./pages/finances/banques/BanquesListe";
import BanquesDetails from "./pages/finances/banques/BanquesDetails";
import TransactionFinVenteForm from "./pages/finances/transactions/TransactionFinVenteForm";
import { fournisseurLoader } from "./functions/achats/fournisseurLoader.js";
import TransactionsForm from "./pages/ventes/transactions/TransactionsForm.js";
import TransactionsClientChantierForm from "./pages/ventes/transactions/TransactionsClientChantierForm.js";
import TransactionsClientDirecteForm from "./pages/ventes/transactions/TransactionsClientDirecteForm.js";
import ChantierClientForm from "./pages/ventes/chantiers/ChantierClientForm.js";
import TransactionsAListe from "./pages/achats/transactions/TransactionsAListe.js";
import TransactionsAForm from "./pages/achats/transactions/TransactionsAForm.js";
import TransactionsADetails from "./pages/achats/transactions/TransactionsADetails.js";
import TransactionsAFournisseurForm from "./pages/achats/transactions/TransactionsAFournisseurForm.js";
import TransactionsAChantierForm from "./pages/achats/transactions/TransactionsAChantierForm.js";
import CarteAnnulerForm from "./pages/personnel/cartesEmployes/CarteAnnulerForm.js";
import CarteModifierForm from "./pages/personnel/cartesEmployes/CarteModifierForm.js";
import CreancesLayout from "./layouts/finances/CreancesLayout.js";
import CreancesListe from "./pages/finances/creances/CreancesListe.js";
import CreancesDetails from "./pages/finances/creances/CreancesDetails.js";
import CreancesForm from "./pages/finances/creances/CreancesForm.js";
import TransactionCreancesForm from "./pages/finances/transactions/TransactionsCreancesForm.js";
import ProjetLayout from "./layouts/achats/ProjetLayout.js";
import ProjetListe from "./pages/achats/projets/ProjetListe.js";
import ProjetDetails from "./pages/achats/projets/ProjetDetails.js";
import ProjetForm from "./pages/achats/projets/ProjetForm.js";
import TransactionsAProjetForm from "./pages/achats/transactions/TransactionsAProjetForm.js";
import TransactionsAPhotoForm from "./pages/achats/transactions/TransactionsAPhotoForm.js";

//create router
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route index element={<Acceuil />} />

      <Route path="login" element={<Login />} />
      <Route
        path="signup"
        element={<Signup />}
        loader={authorizedUsersOnlyLoader}
      />
      <Route path="reset-password" element={<ResetPassword />} />
      <Route
        path="password/reset/confirm/:uid/:token"
        element={<ResetPasswordConfirm />}
      />
      <Route path="activate/:uid/:token" element={<Activate />} />

      <Route path="ventes" element={<VentesLayout />}>
        <Route index element={<VentesHome />} />
        <Route path="transactions" element={<TransactionLayout />}>
          <Route
            index
            element={<TransactionsListe />}
            loader={recetteDepenseLoader}
          />
          <Route
            path=":id"
            element={<TransactionsDetails />}
            loader={recetteDepenseLoader}
          />
          <Route
            path=":id/paiement"
            element={<TransactionFinVenteForm />}
            loader={recetteDepenseLoader}
          />
          <Route
            path="nouvelle-vente"
            element={<TransactionsForm />}
            loader={projetLoader}
          />
          {/* <Route
            path="nouvelle-vente-directe"
            element={<TransactionsDirecteForm />}
            loader={clientLoader}
          /> */}
        </Route>

        <Route path="clients" element={<ClientLayout />}>
          <Route index element={<ClientListe />} loader={clientLoader} />
          <Route path=":id" element={<ClientDetails />} loader={clientLoader} />
          <Route
            path=":id/modifier"
            element={<ClientForm new_ind="N" />}
            loader={clientLoader}
          />
          <Route
            path="nouveau-client"
            element={<ClientForm new_ind="Y" />}
            loader={clientLoader}
          />
          <Route
            path=":id/nouveau-chantier"
            element={<ChantierClientForm new_ind="Y" />}
            loader={clientLoader}
          />
          <Route
            path=":id/nouvelle-vente-immediate"
            element={<TransactionsClientDirecteForm new_ind="Y" />}
            loader={clientLoader}
          />
        </Route>
        <Route path="chantiers" element={<ChantierLayout />}>
          <Route index element={<ChantierListe />} loader={projetLoader} />
          <Route
            path=":id"
            element={<ChantierDetails />}
            loader={projetLoader}
          />
          <Route
            path=":id/nouvelle-vente"
            element={<TransactionsClientChantierForm />}
            loader={projetLoader}
          />
          <Route
            path=":id/modifier"
            element={<ChantierForm new_ind="N" />}
            loader={projetLoader}
          />
          <Route
            path="nouveau-chantier"
            element={<ChantierForm new_ind="Y" />}
            loader={clientLoader}
          />
          <Route
            path=":id/nouvelle-depense"
            element={<TransactionsAChantierForm />}
            loader={projetLoader}
          />
        </Route>
      </Route>

      <Route path="depenses" element={<AchatsLayout />}>
        <Route index element={<AchatsHome />} />

        <Route path="transactions" element={<TransactionALayout />}>
          <Route
            index
            element={<TransactionsAListe />}
            loader={recetteDepenseLoader}
          />
          <Route
            path=":id"
            element={<TransactionsADetails />}
            loader={recetteDepenseLoader}
          />
          <Route
            path=":id/paiement"
            element={<TransactionFinAchatsForm />}
            loader={recetteDepenseLoader}
          />
          <Route
            path="nouvelle-depense"
            element={<TransactionsAForm />}
            loader={projetLoader}
          />
          <Route
            path=":id/modifier-photo"
            element={<TransactionsAPhotoForm />}
            loader={recetteDepenseLoader}
          />
        </Route>

        <Route path="fournisseurs" element={<FournisseurLayout />}>
          <Route
            index
            element={<FournisseurListe />}
            loader={fournisseurLoader}
          />
          <Route
            path=":id"
            element={<FournisseurDetails />}
            loader={fournisseurLoader}
          />
          <Route
            path=":id/modifier"
            element={<FournisseurForm new_ind="N" />}
            loader={fournisseurLoader}
          />

          <Route
            path="nouveau-fournisseur"
            element={<FournisseurForm new_ind="Y" />}
            loader={fournisseurLoader}
          />

          <Route
            path=":id/nouvelle-depense"
            element={<TransactionsAFournisseurForm />}
            loader={fournisseurLoader}
          />
        </Route>

        <Route path="projets" element={<ProjetLayout />}>
          <Route index element={<ProjetListe />} loader={projetLoader} />
          <Route path=":id" element={<ProjetDetails />} loader={projetLoader} />
          {/* <Route
            path=":id/nouvelle-vente"
            element={<TransactionsClientChantierForm />}
            loader={projetLoader}
          /> */}
          <Route
            path=":id/modifier"
            element={<ProjetForm new_ind="N" />}
            loader={projetLoader}
          />
          <Route
            path="nouveau-projet"
            element={<ProjetForm new_ind="Y" />}
            loader={projetLoader}
          />
          <Route
            path=":id/nouvelle-depense"
            element={<TransactionsAProjetForm />}
            loader={projetLoader}
          />
        </Route>
      </Route>

      <Route path="operations" element={<OperationsLayout />}>
        <Route index element={<OperationsHome />} />
        <Route path="stock" element={<StockLayout />}>
          <Route index element={<StockListe />} loader={articleLoader} />
          <Route
            path=":articleId"
            element={<StockDetails />}
            loader={stockLoader}
          />
          <Route
            path="retirer"
            element={<StockRetirer />}
            loader={stockLoader}
          />
          <Route
            path="approvisionner"
            element={<StockAjouter />}
            loader={stockLoader}
          />
        </Route>
        <Route path="articles" element={<ArticleLayout />}>
          <Route index element={<ArticleListe />} loader={articleLoader} />
          <Route
            path=":codebarre"
            element={<ArticleDetails />}
            loader={articleLoader}
          />
          <Route
            path=":codebarre/modifier"
            element={<ArticleForm new_ind="N" />}
            loader={articleLoader}
          />
          <Route
            path=":codebarre/modifier-photo"
            element={<ArticlePhotoForm />}
            loader={articleLoader}
          />
          <Route
            path="nouvel-article"
            element={<ArticleForm new_ind="Y" />}
            loader={articleLoader}
          />
        </Route>

        <Route path="magasins" element={<MagasinLayout />}>
          <Route index element={<MagasinListe />} loader={magasinLoader} />
          <Route
            path=":id"
            element={<MagasinDetails />}
            loader={magasinLoader}
          />
          <Route
            path=":id/modifier"
            element={<MagasinForm new_ind="N" />}
            loader={magasinLoader}
          />
          <Route
            path=":id/modifier-photo"
            element={<MagasinPhotoForm />}
            loader={magasinLoader}
          />
          <Route
            path="nouveau-magasin"
            element={<MagasinForm new_ind="Y" />}
            loader={magasinLoader}
          />
        </Route>
      </Route>

      <Route path="finances" element={<FinancesLayout />}>
        <Route index element={<FinancesHome />} />
        <Route path="transactions" element={<TransactionFinLayout />}>
          <Route
            index
            element={<TransactionFinListe />}
            loader={transactionFinLoader}
          />
          <Route
            path="nouvelle-transaction-fin"
            element={<TransactionFinForm new_ind="Y" />}
            loader={sourceFinLoader}
          />
        </Route>
        <Route path="caisses" element={<CaissesLayout />}>
          <Route index element={<CaissesListe />} loader={sourceFinLoader} />
          <Route
            path=":id"
            element={<CaissesDetails />}
            loader={sourceFinLoader}
          />
        </Route>
        <Route path="banques" element={<BanquesLayout />}>
          <Route index element={<BanquesListe />} loader={sourceFinLoader} />
          <Route
            path=":id"
            element={<BanquesDetails />}
            loader={sourceFinLoader}
          />
        </Route>
        <Route path="creances" element={<CreancesLayout />}>
          <Route
            index
            element={<CreancesListe />}
            loader={recetteDepenseLoader}
          />
          <Route
            path=":id"
            element={<CreancesDetails />}
            loader={recetteDepenseLoader}
          />
          <Route
            path=":id/paiement"
            element={<TransactionCreancesForm />}
            loader={recetteDepenseLoader}
          />
          <Route
            path="nouvelle-creance"
            element={<CreancesForm />}
            loader={projetLoader}
          />
        </Route>
      </Route>
      <Route path="personnel" element={<PersonnelLayout />}>
        <Route index element={<PersonnelHome />} />
        <Route path="employes" element={<EmployeLayout />}>
          <Route index element={<EmployeListe />} loader={employeLoader} />
          <Route
            path=":numeroEmploye"
            element={<EmployeDetails />}
            loader={employeLoader}
          />
          <Route
            path=":numeroEmploye/carte-employe"
            element={<CarteDetails />}
            loader={employeLoader}
          />
          <Route
            path=":numeroEmploye/modifier"
            element={<EmployeForm new_ind="N" />}
            loader={employeLoader}
          />
          <Route
            path=":numeroEmploye/modifier-photo"
            element={<EmployePhotoForm />}
            loader={employeLoader}
          />
          <Route
            path="nouvel-employe"
            element={<EmployeForm new_ind="Y" />}
            loader={employeLoader}
          />
          <Route
            path=":numeroEmploye/carte-employe"
            element={<CarteDetails />}
            loader={employeLoader}
          />
          <Route
            path=":numeroEmploye/carte-employe/attribuer-carte"
            element={<CarteForm />}
            loader={cartePhysiqueLoader}
          />
          <Route
            path=":numeroEmploye/carte-employe/modifier-carte"
            element={<CarteModifierForm />}
            loader={carteEmployeLoader}
          />
          <Route
            path=":numeroEmploye/carte-employe/annuler-carte"
            element={<CarteAnnulerForm />}
            loader={cartePhysiqueLoader}
          />
        </Route>
      </Route>
      <Route path="analytics" element={<AnalyticsLayout />}>
        <Route index element={<AnalyticsHome />} />
        <Route path="stock" element={<AnalyticsStock />} loader={stockLoader} />
        <Route
          path="stock-txns"
          element={<AnalyticsStockTxns />}
          loader={stockTxnsLoader}
        />
        <Route
          path="personnel"
          element={<AnalyticsPersonnel />}
          loader={employeLoader}
        />
      </Route>
    </Route>
  )
);

function App() {
  const color = "white";

  document.body.style.backgroundColor = color;

  return (
    <Provider store={store}>
      <DevisArticlesContextProvider>
        <RouterProvider router={router} />
      </DevisArticlesContextProvider>
    </Provider>
  );
}

export default App;
