import { useEffect, useState } from "react";
import { NavLink, useLoaderData } from "react-router-dom";
import FormTitle from "../../../components/FormTitle";
import sendRecetteDepenseFormData from "../../../functions/ventes/sendRecetteDepenseFormData";
import { connect } from "react-redux";
import useAPIData from "../../../functions/useAPIData";

function TransactionsForm({ user }) {
  const entity = useLoaderData().filter((x) => x.type == "CH");

  let initValue = {
    type: "R",
    montantBrut: "-9999999", //Placeholder - improve in v2
    etatCourant: "N",
    nature_r: "VD",
    // restantAPayerCourant: 0,
  };

  const [formData, setFormData] = useState(initValue);

  const clients = useAPIData("client");

  const [showClient, setShowClient] = useState("");

  function handleChange(event) {
    const { name, value } = event.target;

    setFormData((prevValues) => ({ ...prevValues, [name]: value }));

    setShowClient((prevValues) =>
      name == "projet" && value == ""
        ? "block"
        : name == "projet" && value != ""
        ? "none"
        : prevValues
    );
  }

  // useEffect(() => {
  //   if (formData.etatCourant == "C")
  //     setFormData((prevValues) => ({ ...prevValues, restantAPayerCourant: 0 }));
  //   else if (formData.etatCourant == "N")
  //     setFormData((prevValues) => ({
  //       ...prevValues,
  //       restantAPayerCourant: formData.montantNet,
  //     }));
  // }, [formData.etatCourant]);

  function handleSubmit(event) {
    event.preventDefault();

    // console.log({
    //   effectueePar: user && user.employeNumber,
    //   ...formData,
    // });

    sendRecetteDepenseFormData(
      {
        effectuePar: user && user.employeNumber,
        restantAPayerCourant: formData.montantNet,
        ...formData,
      },
      process.env.REACT_APP_THE_HOST + "/api/recetteDepense/",
      "POST",
      "new",
      "R"
    );
  }
  return (
    <div className="repertoire-employe-layout">
      <div style={{ width: "800px" }}>
        <form method="post" onSubmit={handleSubmit}>
          <FormTitle title={"Nouvelle vente"} />
          <div className="w3-panel w3-light-gray">
            <h3>
              <p>
                Veuillez fournir les infos ci-dessous{" "}
                <span style={{ color: "red" }}>(requis)</span>
              </p>
            </h3>
          </div>

          <div className="w3-row w3-section">
            <div
              className="w3-quarter w3-button"
              style={{ pointerEvents: "none" }}
            >
              <b>
                <span style={{ color: "red" }}>Date</span>
              </b>
            </div>
            <div className="w3-threequarter">
              <input
                className="w3-input w3-border w3-sand"
                name="date"
                type="date"
                value={formData.date}
                onChange={handleChange}
                required={true}
              />
            </div>
          </div>
          <div className="w3-row w3-section">
            <div
              className="w3-quarter w3-button"
              style={{ pointerEvents: "none" }}
            >
              <b>
                <span style={{ color: "red" }}>Chantier relié</span>
              </b>
            </div>
            <div className="w3-threequarter w3-container">
              <select
                name="projet"
                value={formData.projet}
                onChange={handleChange}
                style={{ width: "50%", marginTop: "7.5px" }}
                className="w3-yellow"
              >
                <option value={""}>VENTE IMMEDIATE</option>
                {entity.map((x) => (
                  <option key={x.id} value={x.id}>
                    {x.nom}
                  </option>
                ))}
              </select>
              &nbsp;
              <NavLink
                to={
                  process.env.REACT_APP_THE_HOST +
                  "/ventes/chantiers/nouveau-chantier"
                }
                id="theNavLinkMenu"
                // className="w3-bar-item w3-button w3-ripple w3-orange w3-round w3-margin-left w3-margin-top"
                target="_blank"
              >
                <em>
                  <i className="fa fa-plus-circle" aria-hidden="true" /> Ajouter
                  nouveau chantier
                </em>
              </NavLink>
            </div>
          </div>
          <div className="w3-row w3-section" style={{ display: showClient }}>
            <div
              className="w3-quarter w3-button"
              style={{ pointerEvents: "none" }}
            >
              <b>
                <span style={{ color: "red" }}>Client</span>
              </b>
            </div>
            <div className="w3-threequarter w3-container">
              <select
                name="client"
                value={formData.client}
                onChange={handleChange}
                required={showClient == "none" ? false : true}
                style={{ width: "50%", marginTop: "7.5px" }}
                className="w3-yellow"
              >
                <option value={""}>selectionner</option>
                {clients &&
                  clients.map((x) => (
                    <option key={x.id} value={x.id}>
                      {x.nomEntreprise + "/ " + x.prenom + " " + x.nom}
                    </option>
                  ))}
              </select>
              &nbsp;
              <NavLink
                to={
                  process.env.REACT_APP_THE_HOST +
                  "/ventes/clients/nouveau-client"
                }
                id="theNavLinkMenu"
                // className="w3-bar-item w3-button w3-ripple w3-orange w3-round w3-margin-left w3-margin-top"
                target="_blank"
              >
                <em>
                  <i className="fa fa-plus-circle" aria-hidden="true" /> Ajouter
                  nouveau client
                </em>
              </NavLink>
            </div>
          </div>

          <div className="w3-row w3-section">
            <div
              className="w3-quarter w3-button"
              style={{ pointerEvents: "none" }}
            >
              <b>Montant remise</b>
            </div>
            <div className="w3-threequarter">
              <input
                className="w3-input w3-border w3-sand"
                name="montantRemise"
                type="number"
                value={formData.montantRemise}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="w3-row w3-section">
            <div
              className="w3-quarter w3-button"
              style={{ pointerEvents: "none" }}
            >
              <b>Montant tva</b>
            </div>
            <div className="w3-threequarter">
              <input
                className="w3-input w3-border w3-sand"
                name="montantTva"
                type="number"
                value={formData.montantTva}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="w3-row w3-section">
            <div
              className="w3-quarter w3-button"
              style={{ pointerEvents: "none" }}
            >
              <b>
                <span style={{ color: "red" }}>Net a payer</span>
              </b>
            </div>
            <div className="w3-threequarter">
              <input
                className="w3-input w3-border w3-sand"
                name="montantNet"
                type="number"
                value={formData.montantNet}
                onChange={handleChange}
              />
            </div>
          </div>

          {/* <div className="w3-row w3-section">
            <div
              className="w3-quarter w3-button"
              style={{ pointerEvents: "none" }}
            >
              <b>
                <span style={{ color: "red" }}>Etat courant</span>
              </b>
            </div>
            <div className="w3-threequarter w3-container">
              <select
                name="etatCourant"
                value={formData.etatCourant}
                onChange={handleChange}
                required={true}
                style={{ width: "100%", marginTop: "7.5px" }}
                className="w3-yellow"
              >
                <option value={""}>sélectionner</option>
                <option value="C">Paiement complet</option>
                <option value="P">Paiement partiel</option>
                <option value="N">Pas de paiement</option>
              </select>
            </div>
          </div> */}
          {/* <div className="w3-row w3-section">
            <div
              className="w3-quarter w3-button"
              style={{ pointerEvents: "none" }}
            >
              <b>
                <span style={{ color: "red" }}>Restant a payer</span>
              </b>
            </div>
            <div className="w3-threequarter">
              <input
                className="w3-input w3-border w3-sand"
                name="restantAPayerCourant"
                type="number"
                value={formData.restantAPayerCourant}
                onChange={handleChange}
              />
            </div>
          </div> */}
          <div className="w3-row w3-section">
            <div
              className="w3-quarter w3-button"
              style={{ pointerEvents: "none" }}
            >
              <b>
                <span style={{ color: "red" }}>À payer avant</span>
              </b>
            </div>
            <div className="w3-threequarter">
              <input
                className="w3-input w3-border w3-sand"
                name="dateEcheance"
                type="date"
                value={formData.dateEcheance}
                onChange={handleChange}
                required={true}
              />
            </div>
          </div>

          <div className="w3-row w3-section">
            <div
              className="w3-quarter w3-button"
              style={{ pointerEvents: "none" }}
            >
              <b>Observations</b>
            </div>
            <div className="w3-threequarter">
              <textarea
                className="w3-input w3-border w3-sand"
                name="infoAdd"
                type="text"
                value={formData.infoAdd}
                onChange={handleChange}
              />
            </div>
          </div>

          <button
            className="w3-btn w3-ripple w3-green w3-round"
            style={{ width: "500px" }}
            name="action"
            type="submit"
          >
            Soumettre
          </button>
          <button
            className="w3-btn w3-ripple w3-light-grey w3-round w3-margin-left"
            style={{ width: "284px" }}
            onClick={() =>
              window.location.replace(
                process.env.REACT_APP_THE_HOST + "/ventes/transactions/"
              )
            }
          >
            Annuler
          </button>
        </form>

        <p></p>

        <p></p>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(TransactionsForm);
