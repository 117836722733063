import {
  NavLink,
  Navigate,
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import useAPIData from "../../../functions/useAPIData";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
export default function ClientDetails() {
  const { id } = useParams();

  const entity = useLoaderData();

  const navigate = useNavigate();

  const thisEntity = entity.filter((obj) => obj.id == id)[0];

  const toPrint = useRef(null);

  const handlePrint = useReactToPrint({
    documentTitle:
      "Fiche du client " +
      thisEntity.prenom +
      " " +
      thisEntity.nom +
      " " +
      thisEntity.nomEntreprise,
    removeAfterPrint: true,
  });
  const chantiers = useAPIData("projet").filter(
    (obj) => obj.client == thisEntity.id && obj.type == "CH"
  );

  return (
    <div className="theEmployeDetails">
      <button
        onClick={() => {
          handlePrint(null, () => toPrint.current);
        }}
        className="w3-btn w3-ripple w3-pale-blue w3-round w3-margin-right"
        style={{ width: "300px" }}
      >
        <i className="fa fa-print" aria-hidden="true"></i> Imprimer
      </button>
      <button
        onClick={() => navigate("modifier")}
        className="w3-btn w3-ripple w3-yellow w3-round w3-margin-right"
        style={{ width: "300px" }}
      >
        <i class="fa fa-pencil" aria-hidden="true"></i> Modifier
      </button>
      <NavLink
        to="nouvelle-vente-immediate"
        className="w3-btn w3-ripple w3-purple w3-round w3-margin-right"
        style={{ width: "300px" }}
        target="_blank"
      >
        <i class="fa fa-shopping-cart" aria-hidden="true"></i> Effectuer vente
        immédiate
      </NavLink>
      <NavLink
        to="nouveau-chantier"
        className="w3-btn w3-ripple w3-orange w3-round"
        style={{ width: "300px" }}
        target="_blank"
      >
        <i class="fa fa-briefcase" aria-hidden="true"></i> Ajouter nouveau
        chantier
      </NavLink>
      <div className="toPrint" ref={toPrint}>
        <div
          className="w3-panel w3-text-white"
          style={{ backgroundColor: "gray" }}
        >
          <h3>
            Information sur le client{" "}
            <strong>
              {thisEntity.nomEntreprise == "n/a"
                ? thisEntity.prenom + " " + thisEntity.nom
                : thisEntity.nomEntreprise}
            </strong>
          </h3>
        </div>
        <div className="w3-row">
          <div className="w3-half">
            <div className="w3-card-4 w3-bar">
              <div className="w3-bar-item w3-container w3-cell">
                <p>
                  Nom entreprise: <strong>{thisEntity.nomEntreprise}</strong>
                </p>
                <p>
                  Prenom: <strong>{thisEntity.prenom}</strong>
                </p>
                <p>
                  Nom: <strong>{thisEntity.nom}</strong>
                </p>
                <p>Industrie: {thisEntity.industrieNom}</p>
                <p>Compte rcvable seul: {thisEntity.indRecevable}</p>
                <p>Employé: {thisEntity.indEmploye}</p>
                <p>Adresse: {thisEntity.adresse}</p>
                <p>Ville: {thisEntity.ville}</p>
                <p>Pays: {thisEntity.pays}</p>
                <p>
                  Téléphone(s): {thisEntity.telephone}/ {thisEntity.telephone2}
                </p>
                <p>Email: {thisEntity.email}</p>
                <p>Info additionelles: {thisEntity.infoAdd}</p>
                <p>
                  Derniere mis-a-jour du profile:{" "}
                  {thisEntity.dateMisAJour.substring(0, 10)}
                </p>
              </div>
            </div>
          </div>
          <div className="w3-third w3-margin-left">
            <p>
              <strong>Liste des chantiers du client</strong>
            </p>
            {chantiers && chantiers.length > 0 ? (
              <ul>
                {chantiers &&
                  chantiers.map((x) => (
                    <li>
                      <NavLink
                        to={
                          process.env.REACT_APP_THE_HOST +
                          "/ventes/chantiers/" +
                          x.id
                        }
                        style={{ color: "blue" }}
                        target="_blank"
                      >
                        {x.nom} {x.dateDebut}
                      </NavLink>
                    </li>
                  ))}
              </ul>
            ) : (
              <ul>
                <li>Ce client n'a pas encore de chantier</li>
              </ul>
            )}
            {/* <p>
              <strong>Liste des transactions de vente</strong>
            </p>
            {transactions && transactions.length > 0 ? (
              <ul>
                {transactions &&
                  transactions.map((x) => (
                    <li>
                      <NavLink
                        to={
                          process.env.REACT_APP_THE_HOST +
                          "/ventes/transactions/" +
                          x.id +
                          "/details"
                        }
                        style={{ color: "blue" }}
                      >
                        Transaction No. {x.id} / {x.date}
                      </NavLink>
                    </li>
                  ))}
              </ul>
            ) : (
              <ul>
                <li>Pas de transactions de vente</li>
              </ul>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
}
