import { NavLink } from "react-router-dom";
export default function MenuButton({
  title,
  desc,
  icon,
  w3color,
  link,
  addToClass,
}) {
  return (
    <div class={"w3-third w3-animate-top " + addToClass}>
      <div
        class={"w3-card w3-container w3-round-xlarge " + w3color}
        style={{ minHeight: "300px" }}
      >
        <NavLink
          to={link}
          id="theNavLinkMenu"
          style={{ textDecoration: "None" }}
        >
          <div>
            <h3>{title}</h3>
            <br />
            <i
              class={icon + " w3-margin-bottom w3-text-theme"}
              style={{ fontSize: "120px" }}
            ></i>
            <p>{desc}</p>
          </div>
        </NavLink>
      </div>
    </div>
  );
}
