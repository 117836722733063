import {
  NavLink,
  Navigate,
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import useAPIData from "../../../functions/useAPIData";
import { useState } from "react";
import sendTransactionStockFormData from "../../../functions/operations/sendTransactionStockFormData";
import swal from "sweetalert";
import { connect } from "react-redux";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import LoadingSpinner from "../../../components/LoadingSpinner";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import sendStockFormData from "../../../functions/operations/sendStockFormData";
function StockDetails({ user }) {
  // const loc = useLocation().pathname

  const { articleId } = useParams();

  const stock = useLoaderData();

  const thisStock = stock.filter((obj) => obj.article == articleId);

  const article = useAPIData("article").filter((obj) => obj.id == articleId)[0];

  const chantiers = useAPIData("projet").filter((x) => x.type == "CH");

  const unite = useAPIData("unite").filter(
    (obj) => article && obj.id == article.unite
  )[0];

  const couleur = useAPIData("couleur").filter(
    (obj) => article && obj.id == article.couleur
  )[0];

  const magasin = useAPIData("magasin");

  const transactions = useAPIData("transactionStock").filter(
    (obj) => obj.articleId == articleId
  );

  const toPrint = useRef(null);

  const handlePrint = useReactToPrint({
    documentTitle: "Etats du stock " + articleId,
    removeAfterPrint: true,
  });

  const navigate = useNavigate();

  const [modalApprov, setModalApprov] = useState({
    display: "none",
    showing: 0,
  });

  const [modalNouvel, setModalNouvel] = useState({
    display: "none",
    showing: 0,
  });

  const [modalRetirer, setModalRetirer] = useState({
    display: "none",
    showing: 0,
  });

  const [formData, setFormData] = useState({
    // article: articleId,
    indApprovision: "A",
  });

  const [formDataR, setFormDataR] = useState({
    // article: articleId,
    indApprovision: "R",
    // nature: "VI",
  });

  const [formDataN, setFormDataN] = useState({
    article: articleId,
  });

  function ajoutQuantite(event) {
    const { name, value } = event.target;
    setFormData((prevValues) => ({ ...prevValues, [name]: value }));
  }

  function retraitQuantite(event) {
    const { name, value } = event.target;
    setFormDataR((prevValues) => ({ ...prevValues, [name]: value }));
  }

  function nouvelleQuantite(event) {
    const { name, value } = event.target;
    setFormDataN((prevValues) => ({ ...prevValues, [name]: value }));
  }

  function handleSubmit(event, type) {
    event.preventDefault();

    const s = stock.filter((obj) => obj.id == formDataR.arrivage)[0];

    if (type == "r" && !s) {
      swal({
        text: "Pas de stock enregistre!",
        icon: "error",
        buttons: false,
      });
      setTimeout(function () {
        window.location.replace(
          process.env.REACT_APP_THE_HOST +
            "/operations/stock/" +
            articleId +
            "/details"
        );
      }, 1500);
    } else if (type == "r" && formDataR.quantite > s.quantiteCourante) {
      swal({
        text: "Quantité en stock insuffisante pour cette transaction!",
        icon: "error",
        buttons: false,
      });
      setTimeout(function () {
        window.location.replace(
          process.env.REACT_APP_THE_HOST +
            "/operations/stock/" +
            articleId +
            "/details"
        );
      }, 1500);
    } else if (type == "n")
      sendStockFormData(
        {
          quantiteCourante: formDataN.quantiteInitial,
          ...formDataN,
        },
        process.env.REACT_APP_THE_HOST + "/api/stock/"
      );
    else if (type == "a")
      sendTransactionStockFormData(
        {
          utilisateur: user && user.employeNumber,
          ...formData,
        },
        process.env.REACT_APP_THE_HOST + "/api/transactionStock/"
      );
    else if (type == "r")
      sendTransactionStockFormData(
        {
          utilisateur: user && user.employeNumber,
          ...formDataR,
        },
        process.env.REACT_APP_THE_HOST + "/api/transactionStock/"
      );
  }

  return !user ? (
    <LoadingSpinner color="blue" show={true} />
  ) : (
    <div className="theEmployeDetails">
      <div className="w3-margin-bottom">
        <div
          className="w3-btn w3-ripple w3-pink w3-round w3-border"
          style={{ width: "300px" }}
          onClick={() => setModalRetirer({ display: "block", showing: 1 })}
        >
          <i className="fa fa-minus-circle" aria-hidden="true"></i> Retirer
          (manuellement)
        </div>
        <div
          className="w3-btn w3-ripple w3-indigo w3-round w3-border w3-margin-left"
          style={{ width: "300px" }}
          onClick={() => setModalApprov({ display: "block", showing: 1 })}
        >
          <i className="fa fa-plus-circle" aria-hidden="true"></i>{" "}
          Approvisionner (manuellement)
        </div>
        <div
          className="w3-btn w3-ripple w3-light-blue w3-round w3-border w3-margin-left"
          style={{ width: "300px" }}
          onClick={() => setModalNouvel({ display: "block", showing: 1 })}
        >
          <i className="fa fa-plus-circle" aria-hidden="true"></i> Nouvelle
          arrivage
        </div>
      </div>
      <button
        onClick={() => {
          handlePrint(null, () => toPrint.current);
        }}
        className="w3-btn w3-ripple w3-pale-blue w3-round w3-margin-right"
        style={{ width: "300px" }}
      >
        <i className="fa fa-print" aria-hidden="true"></i> Imprimer
      </button>
      <div className="toPrint" ref={toPrint}>
        <div>
          <div
            className="w3-panel w3-text-white"
            style={{ backgroundColor: "gray" }}
          >
            <h3>
              État du stock de:{" "}
              <strong>
                {article && article.designation} ({couleur && couleur.couleur})
                - {article && article.fabriquant}
              </strong>
            </h3>
          </div>
          <div className="w3-card-4 w3-bar">
            <table className="w3-table-all w3-hoverable">
              <thead>
                <tr className="w3-light-grey">
                  <th>Magasin/ arrivage</th>
                  <th>Date arrivage/ qtité arrivage</th>
                  <th>Quantité actuelle</th>
                  {/* <th>Date derniere mis-a-jour</th> */}
                </tr>
              </thead>
              <tbody>
                {thisStock.map((s) => {
                  return (
                    <tr
                      onClick={() =>
                        navigate(
                          article && "/operations/articles/" + article.codebarre
                        )
                      }
                    >
                      <td>{s.magasin_display + "/ " + s.provenance}</td>
                      <td>{s.dateArrivage + "/ " + s.quantiteInitial}</td>
                      <td
                        style={{
                          backgroundColor:
                            s.quantiteCourante > 0 ? "lightgreen" : "pink",
                        }}
                      >
                        <strong>
                          {Math.round(s.quantiteCourante)} &nbsp;
                          {unite && unite.unite}
                        </strong>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <hr />
          <div>
            <div className="w3-panel w3-light-gray">
              <h3>Entrées et sorties</h3>
            </div>
            <div
              className="the-table"
              // style={{ height: 720, overflow: "scroll" }}
            >
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {/* <TableCell className="w3-sand">Designation</TableCell> */}
                      <TableCell className="w3-light-gray">Date</TableCell>
                      <TableCell className="w3-light-gray">Magasin</TableCell>
                      <TableCell className="w3-light-gray">
                        Nature de la transaction
                      </TableCell>
                      <TableCell align="right" className="w3-light-gray">
                        Quantité
                      </TableCell>
                      <TableCell className="w3-light-gray">Arrivage</TableCell>
                      <TableCell className="w3-light-gray">
                        Observation
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {transactions &&
                      transactions
                        .sort((a, b) => {
                          const nameA = a.date;
                          const nameB = b.date;
                          if (nameA > nameB) {
                            return -1;
                          }
                          if (nameA < nameB) {
                            return 1;
                          }
                          return 0;
                        })
                        .map((row) => (
                          <TableRow
                            key={row.name}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            {/* <TableCell>{row.designation}</TableCell> */}
                            <TableCell>{row.date}</TableCell>
                            <TableCell>{row.magasin_display}</TableCell>
                            {row.indApprovision == "R" ? (
                              <TableCell className="w3-pale-red">
                                {row.nature == "VI"
                                  ? "Vente immédiate"
                                  : row.nature == "UV"
                                  ? "Utilisation Vial"
                                  : row.nature == "CR"
                                  ? "Correction/ ajustement"
                                  : row.nature == "ER"
                                  ? "Erreur"
                                  : row.nature}
                              </TableCell>
                            ) : (
                              <TableCell className="w3-pale-green">
                                {row.nature == "NA"
                                  ? "Nouvel arrivage"
                                  : row.nature == "CI"
                                  ? "Stock initial"
                                  : row.nature == "RC"
                                  ? "Retour de chantier"
                                  : row.nature == "CR"
                                  ? "Correction/ ajustement"
                                  : row.nature == "ER"
                                  ? "Erreur"
                                  : row.nature}
                              </TableCell>
                            )}

                            {row.indApprovision == "R" ? (
                              <TableCell align="right" className="w3-pale-red">
                                {row.quantite} {row.unite}
                              </TableCell>
                            ) : (
                              <TableCell
                                align="right"
                                className="w3-pale-green"
                              >
                                {row.quantite} {row.unite}
                              </TableCell>
                            )}
                            <TableCell>{row.nomArrivage}</TableCell>
                            <TableCell>{row.desc}</TableCell>
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      </div>
      <div
        id="approvisionner"
        class="w3-modal"
        style={{ display: modalApprov.display }}
      >
        <div class="w3-modal-content" style={{ width: "500px" }}>
          <header class="w3-container w3-green">
            <span
              onClick={() => setModalApprov({ display: "none", showing: 0 })}
              class="w3-button w3-display-topright"
            >
              &times;
            </span>
            <h2>Entrez la quantité a ajouter</h2>
          </header>
          <div class="w3-container w3-padding">
            <form onSubmit={(e) => handleSubmit(e, "a")}>
              <p>
                <label className="w3-text-black">
                  <b>
                    <span style={{ color: "red" }}>Quantité</span>
                  </b>
                </label>
                <input
                  className="w3-input w3-border w3-yellow"
                  name="quantite"
                  type="number"
                  value={formData.quantite}
                  style={{ width: "250px" }}
                  required={true}
                  onChange={ajoutQuantite}
                />
              </p>
              <p>
                <label className="w3-text-black">
                  <b style={{ color: "red" }}>Selectionner magasin/ arrivage</b>
                </label>
                &nbsp;
                <select
                  name="arrivage"
                  value={formData.arrivage}
                  onChange={ajoutQuantite}
                  required
                >
                  <option value="">---</option>
                  {thisStock &&
                    thisStock.map((x) => (
                      <option key={x.id} value={x.id}>
                        {x.magasin_display + "/ " + x.provenance}
                      </option>
                    ))}
                </select>
              </p>
              <p>
                <label className="w3-text-black">
                  <b style={{ color: "red" }}>Nature</b>
                </label>
                &nbsp;
                <select
                  name="nature"
                  value={formData.nature}
                  onChange={ajoutQuantite}
                  required
                >
                  <option value="">---</option>
                  <option value="RC">Retour de chantier</option>
                  <option value="AU">Autres</option>
                </select>
              </p>
              <p>
                <label className="w3-text-black">
                  <b>Details additionels</b>
                </label>
                <input
                  className="w3-input w3-border"
                  name="desc"
                  type="text"
                  value={formData.desc}
                  // style={{ width: "500px" }}
                  // required={true}
                  onChange={ajoutQuantite}
                />
              </p>
              <button
                className="w3-btn w3-ripple w3-green w3-round"
                style={{ width: "250px" }}
                name="action"
                type="submit"
              >
                Soumettre
              </button>
            </form>
          </div>
        </div>
      </div>
      <div
        id="retirer"
        class="w3-modal"
        style={{ display: modalRetirer.display }}
      >
        <div class="w3-modal-content" style={{ width: "500px" }}>
          <header class="w3-container w3-orange">
            <span
              onClick={() => setModalRetirer({ display: "none", showing: 0 })}
              class="w3-button w3-display-topright"
            >
              &times;
            </span>
            <h2>Entrez la quantité a retirer</h2>
          </header>
          <div class="w3-container w3-padding">
            <form onSubmit={(e) => handleSubmit(e, "r")}>
              <p>
                <label className="w3-text-black">
                  <b>
                    <span style={{ color: "red" }}>Quantité</span>
                  </b>
                </label>
                <input
                  className="w3-input w3-border w3-yellow"
                  name="quantite"
                  type="number"
                  value={formDataR.quantite}
                  style={{ width: "250px" }}
                  required={true}
                  onChange={retraitQuantite}
                />
              </p>
              <p>
                <label className="w3-text-black">
                  <b style={{ color: "red" }}>Selectionner magasin/ arrivage</b>
                </label>
                &nbsp;
                <select
                  name="arrivage"
                  value={formDataR.arrivage}
                  onChange={retraitQuantite}
                  required
                >
                  <option value="">---</option>
                  {thisStock &&
                    thisStock.map((x) => (
                      <option key={x.id} value={x.id}>
                        {x.magasin_display + "/ " + x.provenance}
                      </option>
                    ))}
                </select>
              </p>
              <p>
                <label className="w3-text-black">
                  <b style={{ color: "red" }}>Nature</b>
                </label>
                &nbsp;
                <select
                  name="nature"
                  value={formDataR.nature}
                  onChange={retraitQuantite}
                  required
                >
                  <option value="">---</option>
                  <option value="VI">VENTE IMMÉDIATE</option>
                  <option value="AU">AUTRE</option>
                  {chantiers &&
                    chantiers.map((x) => (
                      <option
                        key={x.id}
                        value={
                          x.nom +
                          "/ " +
                          x.clientEntreprise +
                          "- " +
                          x.clientPrenom +
                          " " +
                          x.clientNom
                        }
                      >
                        {x.nom +
                          "/ " +
                          x.clientEntreprise +
                          "- " +
                          x.clientPrenom +
                          " " +
                          x.clientNom}
                      </option>
                    ))}
                </select>
              </p>
              <p>
                <label className="w3-text-black">
                  <b>Details additionels</b>
                </label>
                <input
                  className="w3-input w3-border"
                  name="desc"
                  type="text"
                  value={formDataR.desc}
                  onChange={retraitQuantite}
                />
              </p>
              <button
                className="w3-btn w3-ripple w3-orange w3-round"
                style={{ width: "250px" }}
                name="action"
                type="submit"
              >
                Soumettre
              </button>
            </form>
          </div>
        </div>
      </div>
      <div
        id="nouvel"
        class="w3-modal"
        style={{ display: modalNouvel.display }}
      >
        <div class="w3-modal-content" style={{ width: "500px" }}>
          <header class="w3-container w3-light-blue">
            <span
              onClick={() => setModalNouvel({ display: "none", showing: 0 })}
              class="w3-button w3-display-topright"
            >
              &times;
            </span>
            <h2>Enregistrer nouvel arrivage</h2>
          </header>
          <div class="w3-container w3-padding">
            <form onSubmit={(e) => handleSubmit(e, "n")}>
              <p>
                <label className="w3-text-black">
                  <b style={{ color: "red" }}>Date de l'arrivage</b>
                </label>
                &nbsp;
                <input
                  className="w3-input w3-border"
                  name="dateArrivage"
                  type="date"
                  value={formDataN.dateArrivage}
                  style={{ width: "250px" }}
                  required={true}
                  onChange={nouvelleQuantite}
                />
              </p>
              <p>
                <label className="w3-text-black">
                  <b style={{ color: "red" }}>Provenance</b>
                </label>
                &nbsp;
                <input
                  className="w3-input w3-border"
                  name="provenance"
                  type="text"
                  value={formDataN.provenance}
                  style={{ width: "250px" }}
                  required={true}
                  onChange={nouvelleQuantite}
                />
              </p>
              <p>
                <label className="w3-text-black">
                  <b>
                    <span style={{ color: "red" }}>
                      Quantité nouvel arrivage
                    </span>
                  </b>
                </label>
                <input
                  className="w3-input w3-border w3-yellow"
                  name="quantiteInitial"
                  type="number"
                  value={formDataN.quantiteInitial}
                  style={{ width: "250px" }}
                  required={true}
                  onChange={nouvelleQuantite}
                />
              </p>
              <p>
                <label className="w3-text-black">
                  <b style={{ color: "red" }}>Selectionner magasin</b>
                </label>
                &nbsp;
                <select
                  name="magasin"
                  value={formDataN.magasin}
                  onChange={nouvelleQuantite}
                  required
                >
                  <option value="">---</option>
                  {magasin &&
                    magasin.map((x) => (
                      <option key={x.id} value={x.id}>
                        {x.nom}
                      </option>
                    ))}
                </select>
              </p>

              <p>
                <label className="w3-text-black">
                  <b>Details additionels</b>
                </label>
                <input
                  className="w3-input w3-border"
                  name="infoAdd"
                  type="text"
                  value={formDataN.infoAdd}
                  // style={{ width: "500px" }}
                  // required={true}
                  onChange={nouvelleQuantite}
                />
              </p>
              <button
                className="w3-btn w3-ripple w3-green w3-round"
                style={{ width: "250px" }}
                name="action"
                type="submit"
              >
                Soumettre
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(StockDetails);
