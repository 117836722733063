import swal from "sweetalert";
import Barcode from "react-jsbarcode";

export default async function sendTransactionFinFormData(
  formData,
  endpoint,
  method,
  id,
  type
) {
  //Create csrftoken - Required for DJANGO!
  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }

  const csrftoken = getCookie("csrftoken");

  let response;
  let result;

  try {
    response = await fetch(endpoint, {
      method: method,
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken,
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
      body: JSON.stringify(formData),
    });

    result = await response.json();
  } catch (error) {
    swal({
      text: "Erreur du serveur",
      icon: "error",
      buttons: false,
    });
    setTimeout(function () {
      window.location.replace(
        process.env.REACT_APP_THE_HOST + "/finances/transactions/"
      );
    }, 1500);
  }

  if (!response.ok) {
    swal({
      text: "Erreur dans le formulaire",
      icon: "error",
      buttons: false,
    });
    setTimeout(function () {
      window.location.replace(
        type == "R"
          ? process.env.REACT_APP_THE_HOST + "/ventes/transactions/"
          : type == "D"
          ? process.env.REACT_APP_THE_HOST + "/depenses/transactions/"
          : type == "I"
          ? process.env.REACT_APP_THE_HOST + "/finances/creances/"
          : process.env.REACT_APP_THE_HOST + "/finances/transactions/"
      );
    }, 1500);
  } else if (response.ok && type == "R") {
    // if (id > 0) {
    swal({
      text: "La transaction a ete effectuee avec SUCCES!",
      icon: "success",
      buttons: false,
    });

    setTimeout(function () {
      window.location.replace(
        process.env.REACT_APP_THE_HOST + "/ventes/transactions/" + id
      );
    }, 1500);
  }
  // else {
  //   swal({
  //     text: "La transaction a ete effectuee avec SUCCES!",
  //     icon: "success",
  //     buttons: false,
  //   });

  //   setTimeout(function () {
  //     window.location.replace(
  //       process.env.REACT_APP_THE_HOST + "/finances/transactions/"
  //     );
  //   }, 1500);
  // }
  // }
  else if (response.ok && type == "D") {
    // if (id > 0) {
    swal({
      text: "La transaction a ete effectuee avec SUCCES!",
      icon: "success",
      buttons: false,
    });
    setTimeout(function () {
      window.location.replace(
        process.env.REACT_APP_THE_HOST + "/depenses/transactions/" + id
      );
    }, 1500);
  } else if (response.ok && type == "I") {
    // if (id > 0) {
    swal({
      text: "La transaction a ete effectuee avec SUCCES!",
      icon: "success",
      buttons: false,
    });
    setTimeout(function () {
      window.location.replace(
        process.env.REACT_APP_THE_HOST + "/finances/creance/" + id
      );
    }, 1500);
  } else if (response.ok && id == "0") {
    // if (id > 0) {
    swal({
      text: "La transaction a ete effectuee avec SUCCES!",
      icon: "success",
      buttons: false,
    });
    setTimeout(function () {
      window.location.replace(
        process.env.REACT_APP_THE_HOST + "/finances/transactions/"
      );
    }, 1500);
  }
}
