import { useState } from "react";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import useAPIData from "../../../functions/useAPIData";
import sendFournisseurFormData from "../../../functions/achats/sendFournisseurFormData";

export default function FournisseurForm(props) {
  const { id } = useParams(); //get parameters

  const entity = useLoaderData(); //articles

  const thisEntity = entity.filter((obj) => obj.id == id)[0];

  //Load other data sources (from API) as needed
  const industries = useAPIData("industrie");
  // const responsables = useAPIData("employe");

  const navigate = useNavigate();

  //Variables for handling form
  let initValue = {};
  if (props.new_ind == "N") {
    initValue = thisEntity;
  } else {
    initValue = {
      indEntreprise: "N",
    };
  }

  const [formData, setFormData] = useState(initValue);

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData((prevValues) => ({ ...prevValues, [name]: value }));
  }

  function handleSubmit(event) {
    event.preventDefault();

    if (props.new_ind == "Y") {
      // Conditional handling of the form
      // console.log(formData);
      // If new object
      sendFournisseurFormData(
        formData,
        process.env.REACT_APP_THE_HOST + "/api/fournisseur/",
        "POST",
        "new"
      );
    } else if (props.new_ind == "N") {
      // If existing object
      sendFournisseurFormData(
        formData,
        process.env.REACT_APP_THE_HOST +
          "/api/fournisseur/" +
          formData.id +
          "/",
        "PATCH",
        "existing"
      );
    }
  }

  return (
    <div className="repertoire-employe-layout">
      <div style={{ width: "800px" }}>
        {/* The selection content */}

        <form method="post" onSubmit={handleSubmit}>
          <div
            className="w3-panel w3-text-white"
            style={{ backgroundColor: "gray" }}
          >
            {props.new_ind == "Y" ? (
              <h3>
                Veuillez fournir les infos ci-dessous{" "}
                <span style={{ color: "red" }}>(requis)</span>
              </h3>
            ) : (
              <h3>Veuillez fournir les infos ci-dessous</h3>
            )}
          </div>
          <div className="w3-row w3-section">
            <div
              className="w3-quarter w3-button"
              style={{ pointerEvents: "none" }}
            >
              <b>
                <span style={{ color: "red" }}>Prénom</span>
              </b>
            </div>
            <div className="w3-threequarter">
              <input
                className="w3-input w3-border w3-sand"
                name="prenom"
                type="text"
                value={formData.prenom}
                onChange={handleChange}
                required={true}
              />
            </div>
          </div>
          <div className="w3-row w3-section">
            <div
              className="w3-quarter w3-button"
              style={{ pointerEvents: "none" }}
            >
              <b>
                <span style={{ color: "red" }}>Nom</span>
              </b>
            </div>
            <div className="w3-threequarter">
              <input
                className="w3-input w3-border w3-sand"
                name="nom"
                type="text"
                value={formData.nom}
                onChange={handleChange}
                required={true}
              />
            </div>
          </div>
          <div className="w3-row w3-section">
            <div
              className="w3-quarter w3-button"
              style={{ pointerEvents: "none" }}
            >
              <b>
                <span style={{ color: "red" }}>Entreprise ?</span>
              </b>
            </div>
            <div className="w3-threequarter w3-container">
              <select
                name="indEntreprise"
                value={formData.indEntreprise}
                onChange={handleChange}
                required
                style={{ width: "100%", marginTop: "7.5px" }}
                className="w3-yellow"
              >
                <option value={"N"}>Non</option>
                <option value={"O"}>Oui</option>
              </select>
            </div>
          </div>
          <div className="w3-row w3-section">
            <div
              className="w3-quarter w3-button"
              style={{ pointerEvents: "none" }}
            >
              <b>
                <span style={{ color: "red" }}>Nom de l'entreprise</span>
              </b>
            </div>
            <div className="w3-threequarter">
              <input
                className="w3-input w3-border w3-sand"
                name="nomEntreprise"
                type="text"
                placeholder="Entrez n/a si particulier"
                value={formData.nomEntreprise}
                onChange={handleChange}
                required={true}
              />
            </div>
          </div>

          <div className="w3-row w3-section">
            <div
              className="w3-quarter w3-button"
              style={{ pointerEvents: "none" }}
            >
              <b>
                <span style={{ color: "red" }}>Industrie</span>
              </b>
            </div>
            <div className="w3-threequarter w3-container">
              <select
                name="industrie"
                value={formData.industrie}
                onChange={handleChange}
                required
                style={{ width: "50%", marginTop: "7.5px" }}
                className="w3-yellow"
              >
                <option value={""}>selectionner</option>
                {industries.map((x) => (
                  <option key={x.id} value={x.id}>
                    {x.industrie}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="w3-row w3-section">
            <div
              className="w3-quarter w3-button"
              style={{ pointerEvents: "none" }}
            >
              <b>
                <span style={{ color: "red" }}>Sous-traitant?</span>
              </b>
            </div>
            <div className="w3-threequarter w3-container">
              <select
                name="indSoustraitant"
                value={formData.indSoustraitant}
                onChange={handleChange}
                required
                style={{ width: "50%", marginTop: "7.5px" }}
                className="w3-yellow"
              >
                <option value={"N"}>Non</option>
                <option value={"O"}>Oui</option>
              </select>
            </div>
          </div>
          <div className="w3-row w3-section">
            <div
              className="w3-quarter w3-button"
              style={{ pointerEvents: "none" }}
            >
              <b>
                <span style={{ color: "red" }}>Créancier?</span>
              </b>
            </div>
            <div className="w3-threequarter w3-container">
              <select
                name="indCreancier"
                value={formData.indCreancier}
                onChange={handleChange}
                required
                style={{ width: "50%", marginTop: "7.5px" }}
                className="w3-yellow"
              >
                <option value={"N"}>Non</option>
                <option value={"O"}>Oui</option>
              </select>
            </div>
          </div>
          <div className="w3-row w3-section">
            <div
              className="w3-quarter w3-button"
              style={{ pointerEvents: "none" }}
            >
              <b>
                <span style={{ color: "red" }}>Téléphone</span>
              </b>
            </div>
            <div className="w3-threequarter">
              <input
                className="w3-input w3-border w3-sand"
                name="telephone"
                type="text"
                value={formData.telephone}
                onChange={handleChange}
                required={true}
              />
            </div>
          </div>
          <div className="w3-row w3-section">
            <div
              className="w3-quarter w3-button"
              style={{ pointerEvents: "none" }}
            >
              <b>Telephone alternatif</b>
            </div>
            <div className="w3-threequarter">
              <input
                className="w3-input w3-border w3-sand"
                name="telephone2"
                type="text"
                value={formData.telephone2}
                onChange={handleChange}
                required={false}
              />
            </div>
          </div>
          <div className="w3-row w3-section">
            <div
              className="w3-quarter w3-button"
              style={{ pointerEvents: "none" }}
            >
              <b>Adresse</b>
            </div>
            <div className="w3-threequarter">
              <input
                className="w3-input w3-border w3-sand"
                name="adresse"
                type="text"
                value={formData.adresse}
                onChange={handleChange}
                required={false}
              />
            </div>
          </div>
          <div className="w3-row w3-section">
            <div
              className="w3-quarter w3-button"
              style={{ pointerEvents: "none" }}
            >
              <b>Ville</b>
            </div>
            <div className="w3-threequarter">
              <input
                className="w3-input w3-border w3-sand"
                name="ville"
                type="text"
                value={formData.ville}
                onChange={handleChange}
                required={false}
              />
            </div>
          </div>
          <div className="w3-row w3-section">
            <div
              className="w3-quarter w3-button"
              style={{ pointerEvents: "none" }}
            >
              <b>Pays</b>
            </div>
            <div className="w3-threequarter">
              <input
                className="w3-input w3-border w3-sand"
                name="pays"
                type="text"
                value={formData.pays}
                onChange={handleChange}
                required={false}
              />
            </div>
          </div>

          <div className="w3-row w3-section">
            <div
              className="w3-quarter w3-button"
              style={{ pointerEvents: "none" }}
            >
              <b>Email</b>
            </div>
            <div className="w3-threequarter">
              <input
                className="w3-input w3-border w3-sand"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                required={false}
              />
            </div>
          </div>
          <div className="w3-row w3-section">
            <div
              className="w3-quarter w3-button"
              style={{ pointerEvents: "none" }}
            >
              <b>Observations</b>
            </div>
            <div className="w3-threequarter">
              <textarea
                className="w3-input w3-border w3-sand"
                name="infoAdd"
                type="text"
                value={formData.infoAdd}
                onChange={handleChange}
                required={false}
              />
            </div>
          </div>
          <button
            className="w3-btn w3-ripple w3-green w3-round"
            style={{ width: "500px" }}
            name="action"
            type="submit"
          >
            Soumettre
          </button>
          <button
            className="w3-btn w3-ripple w3-light-grey w3-round w3-margin-left"
            style={{ width: "284px" }}
            onClick={() =>
              props.new_ind == "Y"
                ? window.location.replace(
                    process.env.REACT_APP_THE_HOST + "/ventes/clients"
                  )
                : window.location.replace(
                    process.env.REACT_APP_THE_HOST +
                      "/ventes/clients/" +
                      id +
                      "/details"
                  )
            }
          >
            Annuler
          </button>
        </form>

        <p></p>

        <p></p>
      </div>
    </div>
  );
}
