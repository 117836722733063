import { NavLink } from "react-router-dom";
// import logo from "../../data_et_files/vial_mali_logo.png"
import { connect } from "react-redux";
import { logout } from "../../authsys/actions/auth";
import Home from "../../authsys/containers/Home";
import MenuButton from "../../components/MenuButton";
import vial_mali_logo from "../../data_et_files/vial_mali_logo.png";

function Acceuil({ logout, isAuthenticated, user }) {
  return (
    <div className="acceuil">
      <div
        className="w3-container-display w3-bar w3-indigo w3-center"
        style={{ height: "52px" }}
      >
        <div style={{ textDecoration: "none" }}>
          <h3>
            <img
              src={vial_mali_logo}
              height={30}
              width={45}
              style={{ marginTop: "-7px" }}
            />
            &nbsp; SGVM
          </h3>
        </div>
        <NavLink
          onClick={isAuthenticated ? logout : ""}
          id="theNavLinkModule"
          style={{ textDecoration: "none" }}
          className="w3-bar-item w3-button w3-red w3-xlarge w3-display-topright"
        >
          {isAuthenticated ? (
            <span>
              <i class="fa fa-sign-out" aria-hidden="true" />
              Se deconnecter
            </span>
          ) : (
            <span>
              <i class="fa fa-times-circle" aria-hidden="true" />
              Pas connecte
            </span>
          )}
        </NavLink>
      </div>

      {isAuthenticated ? (
        <div class="w3-row-padding w3-center w3-margin-top">
          <header class="w3-container w3-theme w3-padding" id="myHeader">
            <div class="w3-center">
              <p class="w3-xlarge w3-animate-bottom">
                Bienvenue dans le <span style={{ color: "indigo" }}>SGVM</span>,
                le Système intégré de Gestion de la Vial-Mali
              </p>
              <p class="w3-large w3-animate-bottom">
                Veuillez sélectionner parmi les modules suivants
              </p>
            </div>
          </header>
          {user && user.accessVentes > 0 ? (
            <MenuButton
              title={"Ventes et service commercial"}
              desc={
                "Générer des factures de vente, payable à travers des règlements client. Gestion des clients et des chantiers"
              }
              icon={"fa fa-shopping-cart"}
              w3color={"w3-teal"}
              link={"ventes"}
              addToClass={"w3-margin-top"}
            />
          ) : (
            ""
          )}
          {user && user.accessAchat > 0 ? (
            <MenuButton
              title={"Dépenses opérationnelles et achats"}
              desc={
                "Générer des factures de dépenses, tels que les bons d'achats, payable par l'entreprise. Gestion des fournisseurs et sous-traitants"
              }
              icon={"fa fa-arrow-circle-o-down "}
              w3color={"w3-brown"}
              link={"depenses"}
              addToClass={"w3-margin-top"}
            />
          ) : (
            ""
          )}
          {user && user.accessFinances > 0 ? (
            <MenuButton
              title={"Finances"}
              desc={
                "Effectuer les transactions financières tels que les encaissements et déboursements immédiats, n'étant pas relié à des factures."
              }
              icon={"fa fa-money"}
              w3color={"w3-indigo"}
              link={"finances"}
              addToClass={"w3-margin-top"}
            />
          ) : (
            ""
          )}
          {user && user.accessOperations > 0 ? (
            <MenuButton
              title={"Opérations"}
              desc={
                "Gérer le stock en enregistrant les entrées et sorties d'inventaire, gérer les articles et les magasins"
              }
              icon={"fa fa-cogs"}
              w3color={"w3-pink"}
              link={"operations"}
              addToClass={"w3-margin-top"}
            />
          ) : (
            ""
          )}

          {user && user.accessPersonnel > 0 ? (
            <MenuButton
              title={"Personnel"}
              desc={
                "Gérer les informations des employés tels que les informations personnelles et informations reliées aux emplois. Cartes d'employés"
              }
              icon={"fa fa-users"}
              w3color={"w3-purple"}
              link={"personnel"}
              addToClass={"w3-margin-top"}
            />
          ) : (
            ""
          )}
        </div>
      ) : (
        <Home />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

export default connect(mapStateToProps, { logout })(Acceuil);
