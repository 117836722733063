import {
  NavLink,
  Navigate,
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import useAPIData from "../../../functions/useAPIData";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
export default function FournisseurDetails() {
  const { id } = useParams();

  const entity = useLoaderData();

  const navigate = useNavigate();

  const thisEntity = entity.filter((obj) => obj.id == id)[0];

  const toPrint = useRef(null);

  const handlePrint = useReactToPrint({
    documentTitle:
      "Fiche du fournisseur " +
      thisEntity.prenom +
      " " +
      thisEntity.nom +
      " " +
      thisEntity.nomEntreprise,
    removeAfterPrint: true,
  });
  const factures = useAPIData("recetteDepense").filter(
    (obj) => obj.fournisseur == id && obj.nature_d != "CR"
  );

  const creances = useAPIData("recetteDepense").filter(
    (obj) => obj.fournisseur == id && obj.nature_d == "CR"
  );

  // const transactions = useAPIData("recetteDepense").filter(
  //   (obj) => obj.client == thisEntity.id
  // );

  return (
    <div className="theEmployeDetails">
      <button
        onClick={() => {
          handlePrint(null, () => toPrint.current);
        }}
        className="w3-btn w3-ripple w3-pale-blue w3-round w3-margin-right"
        style={{ width: "300px" }}
      >
        <i className="fa fa-print" aria-hidden="true"></i> Imprimer
      </button>
      <button
        onClick={() => navigate("modifier")}
        className="w3-btn w3-ripple w3-yellow w3-round"
        style={{ width: "300px" }}
      >
        <i class="fa fa-pencil" aria-hidden="true"></i> Modifier
      </button>

      <NavLink
        to={"nouvelle-depense"}
        className="w3-btn w3-ripple w3-pink w3-round w3-margin-left"
        style={{ width: "300px" }}
        target="_blank"
      >
        <i class="fa fa-arrow-circle-o-down" aria-hidden="true"></i> Nouvelle
        facture de dépense
      </NavLink>
      <div className="toPrint" ref={toPrint}>
        <div
          className="w3-panel w3-text-white"
          style={{ backgroundColor: "gray" }}
        >
          <h3>
            Information sur le{" "}
            {thisEntity.indCreancier == "O" ? "créancier " : "fournisseur "}
            <strong>
              {thisEntity.nomEntreprise == "n/a"
                ? thisEntity.prenom + " " + thisEntity.nom
                : thisEntity.nomEntreprise}
            </strong>
          </h3>
        </div>
        <div className="w3-row">
          <div className="w3-half">
            <div className="w3-card-4 w3-bar">
              <div className="w3-bar-item w3-container w3-cell">
                <p>
                  Nom entreprise: <strong>{thisEntity.nomEntreprise}</strong>
                </p>
                <p>
                  Prenom: <strong>{thisEntity.prenom}</strong>
                </p>
                <p>
                  Nom: <strong>{thisEntity.nom}</strong>
                </p>
                <p>
                  {thisEntity.indCreancier == "O"
                    ? "Créancier "
                    : "Fournisseur "}{" "}
                  est une entreprise:{" "}
                  <strong>
                    {thisEntity.indEntreprise == "O" ? "Oui" : "Non"}
                  </strong>
                </p>
                <p>Sous-traitant: {thisEntity.indSoustraitant}</p>
                <p>Créancier: {thisEntity.indCreancier}</p>

                <p>Adresse: {thisEntity.adresse}</p>
                <p>Ville: {thisEntity.ville}</p>
                <p>Pays: {thisEntity.pays}</p>
                <p>
                  Téléphone(s): {thisEntity.telephone}/ {thisEntity.telephone2}
                </p>
                <p>Email: {thisEntity.email}</p>
                <p>Info additionelles: {thisEntity.infoAdd}</p>
                <p>
                  Derniere mis-a-jour du profile:{" "}
                  {thisEntity.dateMisAJour.substring(0, 10)}
                </p>
              </div>
            </div>
          </div>
          {thisEntity.indCreancier == "N" ? (
            <div className="w3-third w3-margin-left">
              <p>
                <strong>
                  Liste des factures de dépenses de ce fournisseur
                </strong>
              </p>
              {factures && factures.length > 0 ? (
                <ul>
                  {factures &&
                    factures.map((x) => (
                      <li>
                        <NavLink
                          to={
                            process.env.REACT_APP_THE_HOST +
                            "/depenses/transactions/" +
                            x.id
                          }
                          style={{ color: "blue" }}
                          target="_blank"
                        >
                          Facture No.{x.numero.replace("RD", "")} - {x.date}
                        </NavLink>
                      </li>
                    ))}
                </ul>
              ) : (
                <ul>
                  <li>Ce fournisseur n'a aucune facture présentement</li>
                </ul>
              )}
            </div>
          ) : (
            <div className="w3-third w3-margin-left">
              <p>
                <strong>Liste des créances de ce créancier</strong>
              </p>
              {creances && creances.length > 0 ? (
                <ul>
                  {creances &&
                    creances.map((x) => (
                      <li>
                        <NavLink
                          to={
                            process.env.REACT_APP_THE_HOST +
                            "/finances/creances/" +
                            x.id
                          }
                          style={{ color: "blue" }}
                          target="_blank"
                        >
                          Créance No.{x.numero.replace("RD", "")} - {x.date}
                        </NavLink>
                      </li>
                    ))}
                </ul>
              ) : (
                <ul>
                  <li>Ce créancier n'a aucune créance présentement</li>
                </ul>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
