import MenuButton from "../../components/MenuButton";

export default function VentesHome() {
  return (
    <div className="personnel-page">
      <div className="w3-row">
        <div className="w3-container">
          <header class="w3-container w3-theme w3-padding" id="myHeader">
            <div class="w3-center">
              <p class="w3-xlarge w3-animate-bottom">
                Veuillez selectionner parmi les options suivantes
              </p>
            </div>
          </header>

          <div class="w3-row-padding w3-center w3-margin-top">
            <MenuButton
              title={"Transactions de dépense"}
              // desc={"Générer facture payable de dépenses"}
              icon={"fa fa-arrow-circle-o-down"}
              w3color={"w3-brown"}
              link={"transactions"}
              addToClass={"w3-margin-top"}
            />
            <MenuButton
              title={"Projets (autres que chantiers)"}
              // desc={"Repertoire des fournisseurs"}
              icon={"fa fa-briefcase"}
              w3color={"w3-dark-gray"}
              link={"projets"}
              addToClass={"w3-margin-top"}
            />
            <MenuButton
              title={"Fournisseurs et créanciers"}
              // desc={"Repertoire des fournisseurs"}
              icon={"fa fa-user"}
              w3color={"w3-dark-gray"}
              link={"fournisseurs"}
              addToClass={"w3-margin-top"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
