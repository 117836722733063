import { Link } from "react-router-dom";
import Acceuil from "../../pages/acceuil/Acceuil";
import { connect } from "react-redux";

function Home({ isAuthenticated }) {
  return isAuthenticated ? (
    <Acceuil />
  ) : (
    <div className="w3-container w3-center w3-padding">
      <div className="w3-container w3-margin-top">
        <h1 className="">
          Bienvenue dans le <span style={{ color: "indigo" }}>SGVM</span>
        </h1>
        <p className="">
          Ceci est la propriété de Vial-Mali. Seul le personnel autorisé en a le
          droit d'accès.{" "}
        </p>
        <hr className="" />
        <p>Veuillez vous connecter ou soumettre une requete d'accès.</p>
        <Link to="/login" className="w3-button w3-indigo w3-round">
          <i class="fa fa-power-off" aria-hidden="true" /> Se connecter
        </Link>
        <Link
          to="/signup"
          className="w3-margin-left w3-button w3-light-grey w3-round"
        >
          <i class="fa fa-user-plus" aria-hidden="true" /> Requete d'accès
        </Link>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(Home);
