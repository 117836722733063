import { useState } from "react";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import useAPIData from "../../../functions/useAPIData";
import sendCarteFormData from "../../../functions/personnel/sendCarteFormData";

export default function CarteModifierForm() {
  const { numeroEmploye } = useParams(); //get parameters

  const entity = useLoaderData().filter(
    (x) => x.employe == numeroEmploye && x.indAffectationActive == "O"
  )[0];

  const thisEmploye = useAPIData("employe").filter(
    (obj) => obj.numeroEmploye == numeroEmploye
  )[0];

  const navigate = useNavigate();

  //Variables for handling form
  let initValue = entity;

  const [formData, setFormData] = useState(initValue);

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData((prevValues) => ({ ...prevValues, [name]: value }));
  }

  function handleSubmit(event) {
    event.preventDefault();

    setFormData((prevValues) => ({
      ...prevValues,
      indPermanent: thisEmploye && thisEmploye.typeEmploi,
    }));

    sendCarteFormData(
      formData,
      process.env.REACT_APP_THE_HOST + "/api/carteEmploye/",
      "POST",
      "new"
    );
  }

  return (
    <div className="repertoire-employe-layout">
      <div style={{ width: "800px" }}>
        {/* The selection content */}

        <form method="post" onSubmit={handleSubmit}>
          <div
            className="w3-panel w3-text-white"
            style={{ backgroundColor: "gray" }}
          >
            <h1>Modifier attribution carte</h1>
            <h3>
              Veuillez fournir les informations ci-dessous{" "}
              <span style={{ color: "red" }}>(requis)</span>
            </h3>
          </div>
          <p>
            <label className="w3-text-black">
              <b>
                <span style={{ color: "red" }}>
                  Numero de la carte existante
                </span>
              </b>
            </label>
            <input
              className="w3-input w3-border w3-sand"
              type="text"
              value={entity.numeroCarte}
              readOnly
            />
          </p>
          <p>
            <label className="w3-text-black">
              <b>
                <span style={{ color: "red" }}>Employe</span>
              </b>
            </label>
            <input
              className="w3-input w3-border w3-sand"
              type="text"
              value={
                thisEmploye &&
                thisEmploye.prenom +
                  " " +
                  thisEmploye.nom +
                  " (" +
                  numeroEmploye +
                  ")"
              }
              readOnly
            />
          </p>

          <p>
            <label className="w3-text-black">
              <b>
                <span style={{ color: "red" }}>Date d'expiration</span>
              </b>
            </label>
            <input
              className="w3-input w3-border w3-sand"
              name="dateFinAffectation"
              type="date"
              value={formData.dateFinAffectation}
              onChange={handleChange}
              required={true}
            />
          </p>
          <p>
            <label className="w3-text-black">
              <b>
                <span style={{ color: "red" }}>Type de carte:</span>
              </b>
            </label>{" "}
            &nbsp;
            <select
              name="indAffectationPermanent"
              value={formData.indAffectationPermanent}
              onChange={handleChange}
            >
              <option value="P">Permanent</option>
              <option value="C">Temporaire</option>
            </select>
          </p>
          <button
            className="w3-btn w3-ripple w3-green w3-round"
            style={{ width: "500px" }}
            name="action"
            type="submit"
          >
            Soumettre
          </button>
          <button
            className="w3-btn w3-ripple w3-light-grey w3-round w3-margin-left"
            style={{ width: "284px" }}
            onClick={() =>
              navigate(
                "/personnel/employes/" +
                  numeroEmploye +
                  "/details/carte-employe"
              )
            }
          >
            Annuler
          </button>
        </form>
        <p></p>

        <p></p>
      </div>
    </div>
  );
}
