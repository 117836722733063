import { NavLink, Outlet } from "react-router-dom";
import { connect } from "react-redux";
import NavbarModule from "../../components/NavbarModule";
import LoadingSpinner from "../../components/LoadingSpinner";
import errorBackToAcceuil from "../../functions/errorBackToAcceuil";

function FinancesLayout({ user }) {
  // console.log(accessLevel);
  return !user ? (
    <LoadingSpinner color="indigo" show={true} />
  ) : user.accessFinances < 1 ? (
    errorBackToAcceuil()
  ) : (
    <div className="personnel-layout">
      <NavbarModule
        module={"Finances"}
        w3color={"w3-indigo"}
        icon={"fa fa-money"}
      />

      <Outlet />
    </div>
  );
}
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

export default connect(mapStateToProps)(FinancesLayout);
