import { NavLink, useLoaderData, useParams } from "react-router-dom";
import useAPIData from "../../../functions/useAPIData";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
export default function ChantierDetails() {
  const { id } = useParams();

  const thisEntity = useLoaderData().filter((obj) => obj.id == id)[0];

  console.log(thisEntity);

  const depenses = useAPIData("recetteDepense").filter(
    (x) => x.type == "D" && x.projet == thisEntity.id
  );

  const toPrint = useRef(null);

  const handlePrint = useReactToPrint({
    documentTitle: "Details du chantier " + thisEntity.nom,
    removeAfterPrint: true,
  });

  return (
    <div className="theEmployeDetails">
      <button
        onClick={() => {
          handlePrint(null, () => toPrint.current);
        }}
        className="w3-btn w3-ripple w3-pale-blue w3-round w3-margin-right"
        style={{ width: "300px" }}
      >
        <i className="fa fa-print" aria-hidden="true"></i> Imprimer
      </button>
      <NavLink
        to="modifier"
        className="w3-btn w3-ripple w3-yellow w3-round w3-margin-right"
        style={{ width: "300px" }}
      >
        <i class="fa fa-pencil" aria-hidden="true"></i> Modifier
      </NavLink>

      <NavLink
        to="nouvelle-depense"
        className="w3-btn w3-ripple w3-pink w3-round"
        style={{ width: "300px" }}
        target="_blank"
      >
        <i class="fa fa-pencil" aria-hidden="true"></i> Effectuer une dépense
        reliée
      </NavLink>
      <div className="toPrint" ref={toPrint}>
        <div
          className="w3-panel w3-text-white"
          style={{ backgroundColor: "gray" }}
        >
          <h3>
            Information sur le projet <strong>{thisEntity.nom}</strong>
          </h3>
        </div>
        <div className="w3-row">
          <div className="w3-half">
            <div className="w3-card-4 w3-bar">
              <div className="w3-bar-item w3-container w3-cell">
                <p>
                  Numero de chantier: <strong>{thisEntity.numeroProjet}</strong>
                </p>
                <p>
                  Nom du chantier: <strong>{thisEntity.nom}</strong>
                </p>

                <p>
                  Responsable du projet:{" "}
                  <strong>
                    <NavLink
                      to={
                        process.env.REACT_APP_THE_HOST +
                        "/personnel/employes/" +
                        thisEntity.responsable
                      }
                      style={{ color: "blue" }}
                      target="_blank"
                    >
                      {thisEntity.responsablePrenom +
                        " " +
                        thisEntity.responsableNom}
                    </NavLink>
                  </strong>
                </p>
                <p>
                  Date de debut: <strong>{thisEntity.dateDebut}</strong>
                </p>
                <p>
                  Date de prévu de fin: <strong>{thisEntity.dateFin}</strong>
                </p>
                <p>
                  Durée prévue: <strong>{thisEntity.duree} jours</strong>
                </p>
                <p>Observations: {thisEntity.details}</p>
              </div>
            </div>
          </div>

          <div className="w3-third w3-margin-left">
            <p>
              <strong>Dépenses reliées à ce chantier</strong>
            </p>
            <ul>
              {depenses &&
                depenses.map(
                  (x) =>
                    x.nature_d != "CR" && (
                      <li>
                        <NavLink
                          to={
                            process.env.REACT_APP_THE_HOST +
                            "/depenses/transactions/" +
                            x.id
                          }
                          style={{ color: "blue" }}
                          target="_blank"
                        >
                          Facture No.{x.numero.replace("RD", "")} - {x.date}
                        </NavLink>
                      </li>
                    )
                )}
            </ul>
          </div>

          <div className="w3-third w3-margin-left">
            <p>
              <strong>Créances reliées à ce chantier</strong>
            </p>
            <ul>
              {depenses &&
                depenses.map(
                  (x) =>
                    x.nature_d == "CR" && (
                      <li>
                        <NavLink
                          to={
                            process.env.REACT_APP_THE_HOST +
                            "/finances/creances/" +
                            x.id
                          }
                          style={{ color: "blue" }}
                          target="_blank"
                        >
                          Créance No.{x.numero.replace("RD", "")} - {x.date}
                        </NavLink>
                      </li>
                    )
                )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
