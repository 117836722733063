import { useState } from "react";
import {
  Form,
  Link,
  NavLink,
  Outlet,
  redirect,
  useActionData,
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import useAPIData from "../../../functions/useAPIData";
import sendChantierFormData from "../../../functions/ventes/sendChantierFormData";
import FormTitle from "../../../components/FormTitle";

export default function ChantierClientForm(props) {
  const { id } = useParams(); //get parameters

  const entity = useLoaderData(); //articles

  const thisEntity = entity.filter((obj) => obj.id == id)[0];
  const responsables = useAPIData("employe");
  const clients = useAPIData("client");

  //Variables for handling form
  let initValue = {};
  if (props.new_ind == "N") {
    initValue = { ...thisEntity };
  } else if (props.new_ind == "Y") {
    initValue = {
      type: "CH",
      client: id,
    };
  }

  const [formData, setFormData] = useState(initValue);

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData((prevValues) => ({ ...prevValues, [name]: value }));
  }

  function handleSubmit(event) {
    event.preventDefault();

    console.log(formData);

    // Conditional handling of the form
    if (props.new_ind == "Y") {
      // console.log(formData);
      // If new object
      sendChantierFormData(
        formData,
        process.env.REACT_APP_THE_HOST + "/api/projet/",
        "POST",
        "new"
      );
    }
  }
  return (
    <div className="repertoire-employe-layout">
      <div style={{ width: "800px" }}>
        <form method="post" onSubmit={handleSubmit}>
          {props.new_ind == "Y" ? (
            <div>
              <FormTitle
                title={
                  "Nouveau chantier pour client " +
                  thisEntity.nomEntreprise +
                  "/ " +
                  thisEntity.prenom +
                  " " +
                  thisEntity.nom
                }
              />
            </div>
          ) : (
            <FormTitle title={"Modifier chantier"} />
          )}
          <div className="w3-panel w3-light-gray">
            <h3>
              <p>
                Veuillez fournir les infos ci-dessous{" "}
                <span style={{ color: "red" }}>(requis)</span>
              </p>
            </h3>
          </div>

          <div className="w3-row w3-section">
            <div
              className="w3-quarter w3-button"
              style={{ pointerEvents: "none" }}
            >
              <b>
                <span style={{ color: "red" }}>Nom du chantier</span>
              </b>
            </div>
            <div className="w3-threequarter">
              <input
                className="w3-input w3-border w3-sand"
                name="nom"
                type="text"
                value={formData.nom}
                onChange={handleChange}
                required={true}
              />
            </div>
          </div>

          <div className="w3-row w3-section">
            <div
              className="w3-quarter w3-button"
              style={{ pointerEvents: "none" }}
            >
              <b>
                <span style={{ color: "red" }}>Chef de chantier</span>
              </b>
            </div>
            <div className="w3-threequarter w3-container">
              <select
                name="responsable"
                value={formData.responsable}
                onChange={handleChange}
                required
                style={{ width: "100%", marginTop: "7.5px" }}
                className="w3-yellow"
              >
                <option value={""}>selectionner</option>
                {responsables.map((x) => (
                  <option key={x.id} value={x.numeroEmploye}>
                    {x.prenom + " " + x.nom}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="w3-row w3-section">
            <div
              className="w3-quarter w3-button"
              style={{ pointerEvents: "none" }}
            >
              <b>
                <span style={{ color: "red" }}>Date de début</span>
              </b>
            </div>
            <div className="w3-threequarter">
              <input
                className="w3-input w3-border w3-sand"
                name="dateDebut"
                type="date"
                value={formData.dateDebut}
                onChange={handleChange}
                required={true}
              />
            </div>
          </div>
          <div className="w3-row w3-section">
            <div
              className="w3-quarter w3-button"
              style={{ pointerEvents: "none" }}
            >
              <b>
                <span style={{ color: "red" }}>Date prévue de fin</span>
              </b>
            </div>
            <div className="w3-threequarter">
              <input
                className="w3-input w3-border w3-sand"
                name="dateFin"
                type="date"
                value={formData.dateFin}
                onChange={handleChange}
                required={true}
              />
            </div>
          </div>
          <div className="w3-row w3-section">
            <div
              className="w3-quarter w3-button"
              style={{ pointerEvents: "none" }}
            >
              <b>Observations</b>
            </div>
            <div className="w3-threequarter">
              <textarea
                className="w3-input w3-border w3-sand"
                name="details"
                type="text"
                value={formData.details}
                onChange={handleChange}
              />
            </div>
          </div>

          <button
            className="w3-btn w3-ripple w3-green w3-round"
            style={{ width: "500px" }}
            name="action"
            type="submit"
          >
            Soumettre
          </button>
          <button
            className="w3-btn w3-ripple w3-light-grey w3-round w3-margin-left"
            style={{ width: "284px" }}
            onClick={() =>
              props.new_ind == "Y"
                ? window.location.replace(
                    process.env.REACT_APP_THE_HOST + "/ventes/chantiers"
                  )
                : window.location.replace(
                    process.env.REACT_APP_THE_HOST +
                      "/ventes/chantiers/" +
                      id +
                      "/details"
                  )
            }
          >
            Annuler
          </button>
        </form>

        <p></p>

        <p></p>
      </div>
    </div>
  );
}
