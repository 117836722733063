import { useState } from "react";
import { Navigate, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { reset_password } from "../actions/auth";
import swal from "sweetalert";
import vial_mali_logo from "../../data_et_files/vial_mali_logo.png";
const ResetPassword = ({ reset_password }) => {
  const [requestSent, setRequestSent] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
  });

  const { email } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();

    reset_password(email);
    setRequestSent(true);
  };

  // Is the user authenticated?
  //Redirect to home page
  if (requestSent) {
    swal({
      text: "Requete soumise! Veuillez verifier votre email.",
      icon: "success",
      buttons: false,
    });

    setTimeout(function () {
      window.location.replace("/");
    }, 1500);
  }

  return (
    <div className="acceuil">
      <div className="w3-bar w3-indigo w3-center" style={{ height: "52px" }}>
        <NavLink to="/" style={{ textDecoration: "none" }}>
          <h3>
            <img
              src={vial_mali_logo}
              height={30}
              width={45}
              style={{ marginTop: "-7px" }}
            />{" "}
            &nbsp; SGVM <i class="fa fa-home" aria-hidden="true" />
          </h3>
        </NavLink>{" "}
      </div>
      <div className="w3-container w3-center w3-padding">
        <div className="w3-container margin-top">
          <h1>Reinitialiser votre mot de passe</h1>
          <form onSubmit={(e) => onSubmit(e)}>
            <div className="form-group">
              <input
                className="form-control"
                type="email"
                placeholder="Email"
                name="email"
                value={email}
                onChange={(e) => onChange(e)}
                required
              />
            </div>
            <p></p>
            <button className="w3-button w3-indigo w3-round" type="submit">
              Soumettre
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { reset_password })(ResetPassword);
